import React from 'react';
import './AccessLink.scss'
import { Link } from 'react-router-dom';

interface Props {
    children: React.ReactNode,
    name: string
    link: string
}

const AccessLink: React.FC<Props> = ({ children, name, link }) => {
    return (
        <div className="home-card-pages__item">
            <Link id="ember1572" to={link} className='home-card-pages__item-img'>
                {children}
            </Link>
            {/* <a href="/shop" id="ember1572" className="home-card-pages__item-img">
            </a> */}

            <div className="home-card-pages_access">
                <Link id="ember1572" to={link} className='home-page__link-name'>
                    <span className="home-page-name">
                        {name}
                    </span>
                </Link>
                {/* <a href="/shop" id="ember1574" className="home-page__link-name">                    
                </a> */}
            </div>
        </div>
    );
};

export { AccessLink };