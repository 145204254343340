import React, {FC, ReactNode} from 'react';

interface Props {
    nameColeccion:string
    children: ReactNode
}
const BoxMenu:FC<Props> = ({nameColeccion,children}) => {
    return (
        <>
            <li><hr className="dropdown-divider"/></li>
            <li>
                <span className='dropdown-descripcion'>{nameColeccion}</span>
            </li>
            {
                children
            }
        </>
    );
};

export {BoxMenu};