import React from 'react';
import './Perfil.scss'

import { ProfileUser } from '../../Containers/ProfileUser/ProfileUser';
import { ListClips } from '../../Containers/ListClips/ListClips';
import { ListGroupClips } from '../../Containers/ListGroupClips/ListGroupClips';
import { StoreProducts } from '../../Containers/StoreProducts/StoreProducts';
import { InformationUser } from '../../Containers/InformationUser/InformationUser';
import { FollowerSuggestions } from '../../Containers/FollowerSuggestions/FollowerSuggestions';

const Perfil = () => {
    document.title = 'jorge1235';
    return (
        <div className='Perfil'>
            <ProfileUser
                imgProfile='https://github.com/mdo.png'
                name='Jorge Prueba'
                user='jorge1235'
                desciption='Este es el lema de la empresa Este es el lema de la empresa Este es el lema de la empresa Este es el lema de la empresas'
                noFollowing='25 k'
                noFollowers='3 mill'
            />
            <hr />
            <div className='profile-secction-main'>
                <div className='shop-content'>
                    <nav>
                        <ul className="nav nav-tabs tabsProfile" id="tabsProfile" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="clips-tab" data-bs-toggle="tab" data-bs-target="#clips" type="button" role="tab" aria-controls="clips" aria-selected="false">Clips</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="product-tab" data-bs-toggle="tab" data-bs-target="#product" type="button" role="tab" aria-controls="product" aria-selected="true">Productos Afiliados</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="informacion-tab" data-bs-toggle="tab" data-bs-target="#informacion" type="button" role="tab" aria-controls="informacion" aria-selected="true">Información</button>
                            </li>
                        </ul>
                    </nav>
                    <div className="tab-content" id="tabContent">
                        <div className="tab-pane fade show active" id="clips" role="tabpanel" aria-labelledby="clips-tab">
                            {/* --------- */}
                            <div className="d-flex align-items-start nav-vertical">
                                <aside className="nav flex-column me-3 content-menu" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <div className="card mb-5">
                                        <div className="card-body">
                                            <button className="nav-link button-vertical active" id="v-pills-allClips-tab" data-bs-toggle="pill" data-bs-target="#v-pills-allClips" type="button" role="tab" aria-controls="v-pills-allClips" aria-selected="true">Todos </button>
                                            <button className="nav-link button-vertical" id="v-pills-groupClips-tab" data-bs-toggle="pill" data-bs-target="#v-pills-groupClips" type="button" role="tab" aria-controls="v-pills-groupClips" aria-selected="false">Guardados</button>
                                            <button className="nav-link button-vertical" id="v-pills-liveVideo-tab" data-bs-toggle="pill" data-bs-target="#v-pills-liveVideo" type="button" role="tab" aria-controls="v-pills-liveVideo" aria-selected="false">Live</button>
                                        </div>
                                    </div>
                                    <FollowerSuggestions />
                                </aside>
                                <main className="tab-content w-100" id="v-pills-tabContent">
                                    <section className="tab-pane fade show active" id="v-pills-allClips" role="tabpanel" aria-labelledby="v-pills-allClips-tab">
                                        <ListClips />
                                    </section>
                                    <section className="tab-pane fade" id="v-pills-groupClips" role="tabpanel" aria-labelledby="v-pills-groupClips-tab">
                                        <ListGroupClips />
                                    </section>
                                    <section className="tab-pane fade" id="v-pills-liveVideo" role="tabpanel" aria-labelledby="v-pills-liveVideo-tab">
                                        Live
                                    </section>
                                </main>
                            </div>
                            {/* --------- */}
                        </div>
                        <div className="tab-pane fade" id="product" role="tabpanel" aria-labelledby="product-tab">
                            <StoreProducts />
                        </div>
                        <div className="tab-pane fade" id="informacion" role="tabpanel" aria-labelledby="informacion-tab">
                            <div className="d-flex align-items-start nav-vertical">
                                <aside className="nav flex-column me-3 content-menu" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <FollowerSuggestions />
                                </aside>
                                <main className="tab-content w-100" id="v-pills-tabContent">
                                    <InformationUser />
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { Perfil };