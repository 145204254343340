import React from 'react';
import './Info.scss'

import { Video } from '../../Components/Video/Video';

const Info = () => {
    return (
        <div className='Information-content'>
            <div className='Information-header'>
                <span className='Information-title'>Quienes somos</span>
            </div>
            <div className='Information-body'>
                <p className='Information-description'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                    It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining essentially unchanged.
                    It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>

                <div className='Information-video'>
                    <div className='Information-video--know'>
                        <Video url='https://www.youtube.com/watch?v=1vOqjc1Uh3U' playing={true} />
                    </div>
                </div>
            </div>

            <div className='Information-header mt--6'>
                <span className='Information-title'>Metricas</span>
            </div>
            <div className='Information-body metrica'>
                <div className='metrica-item'>
                    <span className='metrica-text metrica-info-value'>Cuenta Verificada</span>
                </div>
                <div className='row metrica-row'>
                    <div className='col-6'>
                        <div className='metrica-item'>
                            <span className='metrica-text metrica-info-label'>Ventas realizadas</span>
                            &nbsp; &nbsp;
                            <span className='metrica-text metrica-info-value'>10.5k en los ultimos 90 días</span>
                        </div>
                        <div className='metrica-item'>
                            <span className='metrica-text metrica-info-label'>Calificación de éxito</span>
                            &nbsp; &nbsp;
                            <span className='metrica-text metrica-info-value'>3.2 de 5</span>
                        </div>
                        <div className='metrica-item'>
                            <span className='metrica-text metrica-info-label'>Seguidores</span>
                            &nbsp; &nbsp;
                            <span className='metrica-text metrica-info-value'>3,420</span>
                        </div>
                        <div className='metrica-item'>
                            <span className='metrica-text metrica-info-label'>Prestigio</span>
                            &nbsp; &nbsp;
                            <span className='metrica-text metrica-info-value'>Golden</span>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='metrica-item'>
                            <span className='metrica-text metrica-info-label'>Productos activos</span>
                            &nbsp; &nbsp;
                            <span className='metrica-text metrica-info-value'>50</span>
                        </div>
                        <div className='metrica-item'>
                            <span className='metrica-text metrica-info-label'>Mensajes contestados</span>
                            &nbsp; &nbsp;
                            <span className='metrica-text metrica-info-value'>50%</span>
                        </div>
                        <div className='metrica-item'>
                            <span className='metrica-text metrica-info-label'>Taza de cancelaciones</span>
                            &nbsp; &nbsp;
                            <span className='metrica-text metrica-info-value'>3%</span>
                        </div>
                        <div className='metrica-item'>
                            <span className='metrica-text metrica-info-label'>Se unió</span>
                            &nbsp; &nbsp;
                            <span className='metrica-text metrica-info-value'>Marzo 2023</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export { Info };