import React from 'react';

const Address = () => {
    document.title = 'Direcciones';
    return (
        <div>
            Address
        </div>
    );
};

export { Address };