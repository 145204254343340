import React from 'react';
import { Tolink } from '../../Components/ToLink/Tolink';
import './SectionCarrucel.css'

interface Props {
    children: React.ReactNode,
    title: string,
    textlink?: string,
    url?:string
}


const SectionCarrucel:React.FC<Props> = ({children, title, textlink, url='link'}) => {
    return (
        <section className='ui-snapped-section'>
            <div className='ui-snapped-header'>
                <div className='ui-snapped-header_title'>
                    <h3 className='title'>{title}</h3>
                    {
                        textlink &&
                        <Tolink to={url} classlink='ui-snapped-header_link toLink-clr-secundario'>{textlink}</Tolink>
                    }
                </div>
            </div>
            <section className='ui-snapped-section_video'>
                <div className='ui-carousel-snapped__controls-wrapper'>
                    <div className='andes-carousel-snapped'>
                        <div className='andes-carousel-snapped__wrapper'>
                            {children}
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
};

export {SectionCarrucel};