import React from 'react';
import './ScoreComments.scss'

import { Stars } from '../../Components/Stars/Stars';
import { StarProgressBar } from '../../Components/StarProgressBar/StarProgressBar';
import { CommentItem } from '../../Components/CommentItem/CommentItem';

interface Props{
    showNameProduct?: boolean
}


const ScoreComments:React.FC<Props> = ({showNameProduct=false}) => {
    return (
        <div className='ScoreComments'>
            <div className='ScoreComments-info__head'>
                <div className='ScoreComments-info__head--title'>
                    <h3>Calificaciones y comentarios</h3>
                </div>
            </div>
            <div className='ScoreComments-info__body'>
                {
                    showNameProduct &&
                    <>
                        <h4>Xiaomi Redmi 12 Dual SIM 128 GB azul océano 4 GB RAM</h4>
                        <div className='d-flex'>
                            <Stars score={3.2} showTotal={true} ratings={2563} />
                        </div>
                    </>
                }
                <div className='ScoreComments-score'>
                    <div className='row mt-5 mb-5'>
                        <div className='col-4'>
                            <div className="row">
                                <label htmlFor="staticEmail" className="col-sm-4 col-form-label">
                                    <strong>Filtrado por</strong>
                                </label>
                                <div className="col-sm-5">
                                    <select className="form-select form-select-sm" aria-label=".form-select-sm example">
                                        {/* <option selected>Seleccion</option> */}
                                        <option value="1">1 MES</option>
                                        <option value="2">3 MESES</option>
                                        <option value="3">6 MESES</option>
                                        <option value="3">12 MESES</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            <StarProgressBar name='5 estrellas' numberStar={1700} porcentaje={66.3} />
                            <StarProgressBar name='4 estrellas' numberStar={500} porcentaje={19.5} />
                            <StarProgressBar name='3 estrellas' numberStar={300} porcentaje={11.7} />
                            <StarProgressBar name='2 estrellas' numberStar={50} porcentaje={1.9} />
                            <StarProgressBar name='1 estrellas' numberStar={13} porcentaje={0.5} />
                        </div>
                        <div className='col-8'>
                            <div className='content-comments'>
                                <div className="mb-3 row">
                                    <label htmlFor="staticEmail" className="col-sm-8 col-form-label">
                                        <strong>Filtrado por</strong>
                                    </label>
                                    <div className="col-sm-4">
                                        <select className="form-select form-select-sm" aria-label=".form-select-sm example">
                                            <option value="1">Todas las estrellas</option>
                                            <option value="2">5 estrellas</option>
                                            <option value="3">4 estrellas</option>
                                            <option value="3">3 estrellas</option>
                                            <option value="3">2 estrellas</option>
                                            <option value="3">1 estrellas</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row'>
                                    <p>
                                        <samp>Filtro <strong>12 meses 5 estrellas</strong></samp>
                                        &nbsp; &nbsp;
                                        <samp>
                                            Borrar filtro
                                        </samp>
                                    </p>
                                    <p>55 comentarios</p>
                                    <hr />
                                    <div className='col-12 comments-list'>
                                        <CommentItem
                                            name='mauricio'
                                            date='27 de marzo de 2023'
                                            comment='comentarios'
                                        >
                                            <Stars score={5} ratings={2}></Stars>
                                        </CommentItem>
                                        <CommentItem
                                            name='mauricio'
                                            date='27 de marzo de 2023'
                                            comment='comentarios'
                                        >
                                            <Stars score={5} ratings={2}></Stars>
                                        </CommentItem>
                                        <CommentItem
                                            name='mauricio'
                                            date='27 de marzo de 2023'
                                            comment='comentarios'
                                        >
                                            <Stars score={5} ratings={2}></Stars>
                                        </CommentItem>
                                        <CommentItem
                                            name='mauricio'
                                            date='27 de marzo de 2023'
                                            comment='comentarios'
                                        >
                                            <Stars score={5} ratings={2}></Stars>
                                        </CommentItem>
                                        <CommentItem
                                            name='mauricio'
                                            date='27 de marzo de 2023'
                                            comment='comentarios'
                                        >
                                            <Stars score={5} ratings={2}></Stars>
                                        </CommentItem>
                                        <CommentItem
                                            name='mauricio'
                                            date='27 de marzo de 2023'
                                            comment='comentarios'
                                        >
                                            <Stars score={5} ratings={2}></Stars>
                                        </CommentItem>
                                        <CommentItem
                                            name='mauricio'
                                            date='27 de marzo de 2023'
                                            comment='comentarios'
                                        >
                                            <Stars score={5} ratings={2}></Stars>
                                        </CommentItem>
                                        <CommentItem
                                            name='mauricio'
                                            date='27 de marzo de 2023'
                                            comment='comentarios'
                                        >
                                            <Stars score={5} ratings={2}></Stars>
                                        </CommentItem>
                                        <CommentItem
                                            name='mauricio'
                                            date='27 de marzo de 2023'
                                            comment='comentarios'
                                        >
                                            <Stars score={5} ratings={2}></Stars>
                                        </CommentItem>
                                        <CommentItem
                                            name='mauricio'
                                            date='27 de marzo de 2023'
                                            comment='comentarios'
                                        >
                                            <Stars score={5} ratings={2}></Stars>
                                        </CommentItem>
                                        <CommentItem
                                            name='mauricio'
                                            date='27 de marzo de 2023'
                                            comment='comentarios'
                                        >
                                            <Stars score={5} ratings={2}></Stars>
                                        </CommentItem>
                                        <CommentItem
                                            name='mauricio'
                                            date='27 de marzo de 2023'
                                            comment='comentarios'
                                        >
                                            <Stars score={5} ratings={2}></Stars>
                                        </CommentItem>
                                        <CommentItem
                                            name='mauricio'
                                            date='27 de marzo de 2023'
                                            comment='comentarios'
                                        >
                                            <Stars score={5} ratings={2}></Stars>
                                        </CommentItem>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { ScoreComments };