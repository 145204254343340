import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import {BrowserRouter} from "react-router-dom"
import { Layout } from '../Containers/Layout/Layout';
import { NotFound } from '../Views/NotFound/NotFound';
import { Home } from '../Views/Home/Home';
import { ShopHome } from '../Views/ShopHome/ShopHome';
import { Perfil } from '../Views/Perfil/Perfil';
import { Address } from '../Views/Address/Address';
import { Product } from '../Views/Product/Product';
import { ProductScore } from '../Views/ProductScore/ProductScore';
import { Store } from '../Views/Store/Store';
import { Afiliacion } from '../Views/Afiliacion/Afiliacion';
import { WatchVideo } from '../Views/WatchVideo/WatchVideo'
import { Post } from '../Views/Post/Post';
import { Reclips } from '../Views/Reclips/Reclips';

import { Signin } from '../Views/Signin/Signin'
import { Register } from '../Views/Register/Register'

import { ScrollToTop } from '../Components/ScrollToTop/ScrollToTop';


const AppRoutes = () => {
    return (
        //path con parametros es /perfil/:id y el link es http://localhost:3000/perfil/yorch256
        <BrowserRouter>
            <ScrollToTop/>
            <Routes>
                 {/* Rutas dentro del Layout */}
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="/shop" element={<ShopHome />} />
                    <Route path="/usuario/:user" element={<Perfil />} />
                    <Route path="/product/:product" element={<Product />} />
                    <Route path="/product/score/:product" element={<ProductScore />} />
                    <Route path="/store/:idStore" element={<Store />} />
                    <Route path="/direcciones" element={<Address />} />
                    <Route path="/afiliacion" element={<Afiliacion />} />
                    <Route path="/watch" element={<WatchVideo />} />
                    <Route path="/reclips/product" element={<Reclips />} />
                    <Route path="/publicar" element={<Post />} />
                    {/* Ruta para página no encontrada */}
                    <Route path="*" element={<NotFound />} />
                </Route>

                {/* Rutas fuera del Layout */}
                <Route path="/register" element={<Register />} />
                <Route path="/signin" element={<Signin />} />     

            </Routes>
        </BrowserRouter>
    );
};

export { AppRoutes };