import React, { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import './Layout.css'

import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';

const Layout = () => {
    return (
        <div className='Layout'>
            <div className='prototipo'>
                <span><strong>Clisvi</strong> está en proceso de construcción. Las vistas que está observando son únicamente un prototipo.</span>
            </div>
            <Header />
            <main className='main'>
                <div className='main-home'>
                    <Outlet/>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export { Layout };