import React from "react";
import "./VideoInfoItem.css";

interface Props {
  children: React.ReactNode
  classItem?: string
}

const VideoProductItem: React.FC<Props> = ({ children, classItem = 'VideoProductItem' }) => {
  return (
    <div role="group" className={`${classItem} card-column`}>
      <div className="card-column-cont">
        {children}
      </div>
    </div>
  );
};

export { VideoProductItem };
