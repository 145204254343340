import React from 'react';
import './MyStore.scss'


import { Tolink } from '../ToLink/Tolink';

interface Props {
    srcImage: string
    name: string
    linkPage: string
    notifications: number
    linkNotifications: string
}

const MyStore: React.FC<Props> = ({ srcImage, name, linkPage, notifications, linkNotifications }) => {
    return (
        <div className='MyStore home-card-pages__item'>
            <Tolink to={linkPage} classlink='home-card-pages__item-img'>
                <img src={srcImage}
                    loading="lazy"
                    alt={`Logotipo de ${name}`}
                    className="home-card-image"
                />
            </Tolink>

            <div className="home-card-pages">
                <Tolink to={linkPage} classlink='home-page__link-name'>
                    <span className="home-page-name">
                        {name}
                    </span>
                </Tolink>

                <Tolink to={linkNotifications} classlink='home-page__link-notif'>
                    <span className="home-page__notif-text">
                        Notificaciones
                    </span>
                    <span className="home-page__notif-number">
                        {notifications}
                    </span>
                </Tolink>
            </div>
        </div>
    );
};

export { MyStore };