import React from 'react';
import './WatchVideo.scss'

import { useNavigate, useSearchParams } from 'react-router-dom';
import { OnlyVideo } from '../../Components/OnlyVideo/OnlyVideo';
import { Button } from '../../Components/Button/Button';
import { InfoUser } from '../../Components/InfoUser/InfoUser';
import { Stars } from '../../Components/Stars/Stars';
import { Reactions } from '../../Components/Reactions/Reactions';
import { OnlyVideoItem } from '../../Components/OnlyVideoItem/OnlyVideoItem';
import { DescriptionVideo } from '../../Components/VideoInfoItem/DescriptionVideo';
import { VideoProductItem } from '../../Components/VideoProductItem/VideoInfoItem';
import { DetailProduct } from '../../Components/VideoProductItem/DetailProduct';
import { Tolink } from '../../Components/ToLink/Tolink';
import { Comments } from '../../Components/Comments/Comments';

const WatchVideo = () => {
    document.title = "Clisvi watch";

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const clip = searchParams.get('clip');
    const vista = searchParams.get('v');

    const Dictionary = {
        'SHOP': 'shop',
        'CLIP': 'clip',
    }
    const [view, setView] = React.useState(vista)

    React.useEffect(() => {
        console.log('Valor de clip:', clip);
    }, [clip]);

    React.useEffect(() => {
        setView(vista)
        console.log('Valor de la vista:', vista);
    }, [vista]);


    return (
        <div className='Watch watch-content'>
            <div className='watch-video'>
                <div className='video-social--display'>
                    <OnlyVideo />
                </div>
                <div className='watch-detail'>
                    <div className='watch-detail__user-activity'>
                        <div className='watch-detail__user'>
                            <div className='watch-detail__user-info'>
                                {
                                    view === Dictionary.CLIP &&
                                    <>
                                        <InfoUser
                                            showVerify={true}
                                            name='Usuario'
                                            link='/usuario/Platzi'
                                            imgProfile='https://github.com/mdo.png'
                                            user=""
                                        >                                            
                                            <span className="publication-actor__description">
                                                <span aria-hidden="true">205.781 seguidores</span>
                                                <span className="visually-hidden">205.781 seguidores</span>
                                            </span>
                                        </InfoUser>
                                        <Button classBtn='button_secundario ms-5'>Seguir</Button>
                                    </>
                                }
                                {
                                    view === Dictionary.SHOP &&
                                    <>
                                        <div>
                                            <div className='information-seller--InfoSeller'>
                                                <p>Vendido por:</p>
                                                <Button classBtn='button_secundario ms-5'>Seguir</Button>
                                                <div className='information-seller-info'>
                                                    <InfoUser
                                                        showVerify={true}
                                                        name='Tienda A'
                                                        link='/store/tienda'
                                                        imgProfile='https://avatars.githubusercontent.com/u/197597?v=4'
                                                        user=""
                                                    >
                                                        <Stars score={4.5} ratings={2536} />
                                                        <div className='information-sales-cont-all'>
                                                            <span className='information-sales-all'>10K Ventas en los ulttimos 90 días</span>
                                                        </div>
                                                    </InfoUser>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>

                        </div>
                        <div className='watch-detail__activity'>
                            <div className='watch-details--bar__container-reaccions'>
                                <span role='group' className="social-details-bar__action--reaccions social-reactions-accion-button dropup">
                                    <Reactions classButton='social-details-reaccions__action--button social-bar-button'>
                                        <i className="bi bi-hand-thumbs-up"></i>
                                    </Reactions>
                                </span>
                                <span role='group' className="social-details-bar__action--reaccions reactions-react-button">
                                    <button type="button" className="social-details-reaccions__action--button social-bar-button">
                                        <i className="bi bi-repeat"></i>Compartir
                                    </button>
                                </span>
                                <span role='group' className="social-details-bar__action--reaccions reactions-react-button">
                                    <button type="button" className="social-details-reaccions__action--button social-bar-button">
                                        <i className="bi bi-collection-play-fill"></i>Guardar
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='watch-detail__contentDescription'>
                        {
                            view === Dictionary.CLIP &&
                            <>
                                <span className="break-words">
                                    <span>
                                        <span dir="ltr">
                                            En esta reseña, te mostramos en detalle el nuevo Smartphone XYZ. Descubre todas sus características, su rendimiento y nuestras impresiones sobre este dispositivo. Desde el diseño hasta la calidad de la cámara y el rendimiento del procesador,
                                            &nbsp;
                                            <a href="#" className='toLink-clr-secundario font-bold' data-attribute-index="0" data-entity-type="MINI_COMPANY">Link 1</a>&nbsp;y&nbsp;
                                            <a href="#" className='toLink-clr-secundario font-bold' data-attribute-index="2" data-entity-type="MINI_COMPANY">Link 2</a>. <br />
                                            ¡Nos veremos de nuevo en 2024!
                                        </span>
                                    </span>
                                </span>
                            </>
                        }
                        {
                            view === Dictionary.SHOP &&
                            <>
                                <p>Nuevo | +2K vendidos</p>
                                <Tolink to='/product/34535-343fafsfasdfc' classlink='toLink'>
                                    <h1>Xiaomi Redmi 12 Dual SIM 128 GB azul océano 4 GB RAM</h1>
                                </Tolink>
                                <div className='d-flex'>
                                    <Tolink to='/product/score/34535-343fafsfasdfc' classlink='toLink-black_hover'>
                                        <Stars score={3.2} showTotal={true} ratings={2563} />
                                    </Tolink>
                                </div>
                                <div className='text-end'>
                                    <Button classBtn='button_secundario active'>Agregar al carrito</Button>
                                </div>
                            </>
                        }
                    </div>
                </div>

                <div className=''>
                    <div className="" id="tabContent">
                        <div className="tab-pane fade show active" id="reclips" role="tabpanel" aria-labelledby="reclips-tab">
                            <div className='watch-video_groupVideos'>
                                <OnlyVideoItem
                                    linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                    linkImg="https://images.pexels.com/photos/8368970/pexels-photo-8368970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                    anclado={false}
                                >
                                    <DescriptionVideo linkVideoView='/watch?clip=7283157820948614406&v=clip' listTag={['cocina', 'desinfectante']} />
                                </OnlyVideoItem>
                                <VideoProductItem>
                                    <OnlyVideoItem
                                        linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                                        linkVideoView='/watch?clip=7283157820948614406&v=shop'
                                    >
                                        <DetailProduct publicity={false} linkDetail="/watch?clip=7283157820948614406&v=shop" />
                                    </OnlyVideoItem>
                                </VideoProductItem>
                                <OnlyVideoItem
                                    linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                    linkImg="https://images.pexels.com/photos/8368970/pexels-photo-8368970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                    anclado={false}
                                >
                                    <DescriptionVideo linkVideoView='/watch?clip=7283157820948614406&v=clip' listTag={['cocina', 'desinfectante']} />
                                </OnlyVideoItem>
                           
                                <VideoProductItem>
                                    <OnlyVideoItem
                                        linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                                        linkVideoView='/watch?clip=7283157820948614406&v=shop'
                                    >
                                        <DetailProduct publicity={false} linkDetail="/watch?clip=7283157820948614406&v=shop" />
                                    </OnlyVideoItem>
                                </VideoProductItem>
                                <VideoProductItem>
                                    <OnlyVideoItem
                                        linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                                        linkVideoView='/watch?clip=7283157820948614406&v=shop'
                                    >
                                        <DetailProduct publicity={false} linkDetail="/watch?clip=7283157820948614406&v=shop" />
                                    </OnlyVideoItem>
                                </VideoProductItem>
                                <VideoProductItem>
                                    <OnlyVideoItem
                                        linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                                        linkVideoView='/watch?clip=7283157820948614406&v=shop'
                                    >
                                        <DetailProduct publicity={false} linkDetail="/watch?clip=7283157820948614406&v=shop" />
                                    </OnlyVideoItem>
                                </VideoProductItem>
                                <OnlyVideoItem
                                    linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                    linkImg="https://images.pexels.com/photos/8368970/pexels-photo-8368970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                    anclado={false}
                                >
                                    <DescriptionVideo linkVideoView='/watch?clip=7283157820948614406&v=clip' listTag={['cocina', 'desinfectante']} />
                                </OnlyVideoItem>
                                <OnlyVideoItem
                                    linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                    linkImg="https://images.pexels.com/photos/8368970/pexels-photo-8368970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                    anclado={false}
                                >
                                    <DescriptionVideo linkVideoView='/watch?clip=7283157820948614406&v=clip' listTag={['cocina', 'desinfectante']} />
                                </OnlyVideoItem>
                                <VideoProductItem>
                                    <OnlyVideoItem
                                        linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                                        linkVideoView='/watch?clip=7283157820948614406&v=shop'
                                    >
                                        <DetailProduct publicity={false} linkDetail="/watch?clip=7283157820948614406&v=shop" />
                                    </OnlyVideoItem>
                                </VideoProductItem>
                                <VideoProductItem>
                                    <OnlyVideoItem
                                        linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                                        linkVideoView='/watch?clip=7283157820948614406&v=shop'
                                    >
                                        <DetailProduct publicity={false} linkDetail="/watch?clip=7283157820948614406&v=shop" />
                                    </OnlyVideoItem>
                                </VideoProductItem>
                                <VideoProductItem>
                                    <OnlyVideoItem
                                        linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                                        linkVideoView='/watch?clip=7283157820948614406&v=shop'
                                    >
                                        <DetailProduct publicity={false} linkDetail="/watch?clip=7283157820948614406&v=shop" />
                                    </OnlyVideoItem>
                                </VideoProductItem>
                        
                            </div>
                        </div>


                    </div>
                </div>


            </div>
            <div className='Watch-listVideos'>
                <div className='Watch-listVideos_content'>
                    <div className='social-comment-cont--input'>
                        <div className="social-comment__comp-input">
                            <label htmlFor="inputPassword" className="social-comment__label col-form-label">
                                <span className="social-comment__label-img">
                                    <div>
                                        <img src="https://github.com/mdo.png" loading="lazy" alt="Foto de Jorge Balderas" id="ember137" className="EntityPhoto-circle-0 evi-image lazy-image ember-view" />
                                    </div>
                                </span>
                            </label>
                            <div className="social-comment--input">
                                <textarea name="" className="form-control" id="inputPassword" cols={20} rows={1} placeholder='Escribe tu comentario'></textarea>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className='MT-5'>
                        <Comments moreComments={false}>
                            <Comments>
                                <Comments> </Comments>
                            </Comments>
                            <Comments> </Comments>
                        </Comments>
                        <Comments moreComments={false}>
                            <Comments>
                                <Comments> </Comments>
                            </Comments>
                            <Comments> </Comments>
                        </Comments>
                        <Comments moreComments={false}>
                            <Comments>
                                <Comments> </Comments>
                            </Comments>
                            <Comments> </Comments>
                        </Comments>
                        <Comments moreComments={true}>
                            <Comments>
                                <Comments> </Comments>
                            </Comments>
                            <Comments> </Comments>
                        </Comments>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { WatchVideo };