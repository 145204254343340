import React from 'react';
import './Store.scss'
import { ProfileStore } from '../../Containers/ProfileStore/ProfileStore';
import { ListClips } from '../../Containers/ListClips/ListClips';
import { StoreProducts } from '../../Containers/StoreProducts/StoreProducts';
import { ListGroupClips } from '../../Containers/ListGroupClips/ListGroupClips';
import { InformationStore } from '../../Containers/InformationStore/InformationStore';
import { FollowerSuggestions } from '../../Containers/FollowerSuggestions/FollowerSuggestions';
import { Button } from '../../Components/Button/Button';
import { Tolink } from '../../Components/ToLink/Tolink';

import Oro from '../../Assets/Image/Prestigio/oro.png'

const Store = () => {
    document.title = "Tienda Prueba";
    return (
        <div className='Shop w-100'>
            <ProfileStore
                imgProfile='https://avatars.githubusercontent.com/u/197597?v=4'
                name='Tienda Prueba'
                user='tienda'
                showStarts={true}
                score={3}
                ratings={2593}
                desciption='Este es el lema de la empresa Este es el lema de la empresa Este es el lema de la empresa Este es el lema de la empresas'
                noFollowing='25 Mil'
                noFollowers='3,445'
                ImgPrestigio={Oro}
            />
            <hr />
            <div className='profile-secction-main'>
                <div className='shop-content'>
                    <nav>
                        <ul className="nav nav-tabs tabsShop" id="tabsShop" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="products-tab" data-bs-toggle="tab" data-bs-target="#products" type="button" role="tab" aria-controls="products" aria-selected="false">Productos</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link " id="clips-tab" data-bs-toggle="tab" data-bs-target="#clips" type="button" role="tab" aria-controls="clips" aria-selected="false">Clips</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="conocenos-tab" data-bs-toggle="tab" data-bs-target="#conocenos" type="button" role="tab" aria-controls="conocenos" aria-selected="true">Conocenos</button>
                            </li>
                        </ul>
                    </nav>
                    <div className="tab-content" id="tabContent">
                        <div className="tab-pane fade show active" id="products" role="tabpanel" aria-labelledby="products-tab">
                            <StoreProducts />
                        </div>
                        <div className="tab-pane fade" id="clips" role="tabpanel" aria-labelledby="clips-tab">
                            <div className="d-flex align-items-start nav-vertical">
                                <aside className="nav flex-column me-3 content-menu" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <div className='cont-button-sell'>
                                        <Tolink to='/vender'>
                                            <Button>Quiero vender</Button>
                                        </Tolink>
                                    </div>

                                    <div className="card">
                                        <div className="card-body">
                                            <button className="nav-link button-vertical active" id="v-pills-allClips-tab" data-bs-toggle="pill" data-bs-target="#v-pills-allClips" type="button" role="tab" aria-controls="v-pills-allClips" aria-selected="true">Todos (253)</button>
                                            <button className="nav-link button-vertical" id="v-pills-groupClips-tab" data-bs-toggle="pill" data-bs-target="#v-pills-groupClips" type="button" role="tab" aria-controls="v-pills-groupClips" aria-selected="false">Guardados</button>
                                            <button className="nav-link button-vertical" id="v-pills-reclips-tab" data-bs-toggle="pill" data-bs-target="#v-pills-reclips" type="button" role="tab" aria-controls="v-pills-reclips" aria-selected="false">Reclips (21)</button>
                                            <button className="nav-link button-vertical" id="v-pills-liveVideo-tab" data-bs-toggle="pill" data-bs-target="#v-pills-liveVideo" type="button" role="tab" aria-controls="v-pills-liveVideo" aria-selected="false">Live</button>
                                        </div>
                                    </div>

                                    <FollowerSuggestions />

                                </aside>
                                <main className="tab-content w-100" id="v-pills-tabContent">
                                    <section className="tab-pane fade show active" id="v-pills-allClips" role="tabpanel" aria-labelledby="v-pills-allClips-tab">
                                        <ListClips />
                                    </section>
                                    <section className="tab-pane fade" id="v-pills-groupClips" role="tabpanel" aria-labelledby="v-pills-groupClips-tab">
                                        <ListGroupClips />
                                    </section>
                                    <section className="tab-pane fade" id="v-pills-reclips" role="tabpanel" aria-labelledby="v-pills-reclips-tab">
                                        <p>Clips de usuarios que nos cuentan su experiencia de comprar con <strong>Tienda Prueba</strong> y nuestros productos.</p>
                                        <hr />
                                        <ListClips />

                                    </section>
                                    <section className="tab-pane fade" id="v-pills-liveVideo" role="tabpanel" aria-labelledby="v-pills-liveVideo-tab">
                                        Live
                                    </section>
                                </main>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="conocenos" role="tabpanel" aria-labelledby="conocenos-tab">
                            <InformationStore />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { Store };