import React from 'react';
import star from '../../Assets/Image/Star/star.png'
import './Stars.css'

interface Props {
    score: number
    showTotal?: boolean
    ratings: number
}
interface Score {
    _1_star: string,
    _2_star: string,
    _3_star: string,
    _4_star: string,
    _5_star: string,
}
const Stars: React.FC<Props> = ({ score, showTotal = false, ratings }) => {

    const [valueStars, setValueStars] = React.useState<Score>({ _1_star: '', _2_star: '', _3_star: '', _4_star: '', _5_star: '' })
    const calculeColor = () => {
        let _1_star = '';
        let _2_star = '';
        let _3_star = '';
        let _4_star = '';
        let _5_star = '';

        //https://support.trustpilot.com/hc/es/articles/201748946-El-TrustScore-y-la-valoraci%C3%B3n-en-estrellas
        if (score > 0) {
            if (score < 5) {
                if (score > 0 && score <= 0.5) _1_star = 'star-color-medium'
                if (score > 0.5) _1_star = 'star-color-full'

                if (score >= 1 && score <= 1.5) _2_star = 'star-color-medium'
                if (score > 1.5) _2_star = 'star-color-full'

                if (score >= 2 && score <= 2.5) _3_star = 'star-color-medium'
                if (score > 2.5) _3_star = 'star-color-full'

                if (score >= 3 && score <= 3.5) _4_star = 'star-color-medium'
                if (score > 3.5) _4_star = 'star-color-full'

                if (score >= 4 && score <= 4.5) _5_star = 'star-color-medium'
                if (score > 4.5) _5_star = 'star-color-full'
            } else {
                _1_star = 'star-color-full'
                _2_star = 'star-color-full'
                _3_star = 'star-color-full'
                _4_star = 'star-color-full'
                _5_star = 'star-color-full'
            }
        }
        setValueStars({
            _1_star,
            _2_star,
            _3_star,
            _4_star,
            _5_star,
        })
    }

    React.useEffect(() => {
        calculeColor();
    }, [score])

    return (
        <div className='info-stores--secction-star'>
            <div className='secction-star'>
                <div className={`star_item ${valueStars._1_star}`}>
                    <img src={star} alt="star" sizes="5px" />
                </div>
                <div className={`star_item ${valueStars._2_star}`}>
                    <img src={star} alt="star" sizes="5px" />
                </div>
                <div className={`star_item ${valueStars._3_star}`}>
                    <img src={star} alt="star" sizes="5px" />
                </div>
                <div className={`star_item ${valueStars._4_star}`}>
                    <img src={star} alt="star" sizes="5px" />
                </div>
                <div className={`star_item ${valueStars._5_star}`}>
                    <img src={star} alt="star" sizes="5px" />
                </div>
                <span className='info-stores-score'>
                    {score} de 5
                </span>
                {
                    showTotal &&
                    <>
                        &nbsp;
                        -
                        &nbsp;
                        <span className='info-stores--qualification-text'>{ratings} calificaciones</span>
                    </>
                }
            </div>
        </div>
    );
};

export { Stars };