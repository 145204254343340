import React from 'react';
import './OnlyVideoItem.scss'

import { VideoPlayer } from '../VideoPlayer/VideoPlayer';

interface Props {
    children?: React.ReactNode
    linkVideoView: string
    linkImg?: string
    videoViews?: string
    linkToPage?: string
    nVentas?: string
    anclado?: boolean
    isVenta?: boolean
}

const OnlyVideoItem: React.FC<Props> = ({
    children,
    anclado = false,
    linkVideoView
}) => {

    return (
        <div className="item-clip-contaner">
            <div data-e2e="user-post-item" className="item-clip--content-post" role="button" aria-label="Ver en pantalla completa">
                <div className="item-clip-wrapper">
                    <div className="item-clip__wrapper">
                        <div className="item-clip__post">
                            <div className="css-item-clip">
                                <span className='css-span VideoPlayer_visible'>
                                    <VideoPlayer
                                        modelight={true}
                                        linkToPage={linkVideoView}
                                    />
                                </span>
                            </div>
                        </div>

                        <div role="button" aria-label="Denunciar" id="browser-mode-report-btn" data-e2e="browse-report" className="css-pj2fot-DivReportText">
                            {/* active */}
                            <i className="bi bi-heart-fill"></i>
                        </div>
                    </div>
                    {
                        anclado &&
                        <div className="item-clip__anchored">
                            <div className="item-clip__StyleAnchored">
                                <div data-e2e="video-card-badge" className="item-clip--anchored">Anclado</div>
                            </div>
                        </div>
                    }
                </div>
            </div>

            {children}

        </div>
    );
};

export { OnlyVideoItem };