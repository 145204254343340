import React from 'react';
import { Icon } from '../../Icon/Icon';
import { ItemNotification } from './itemNotification';
import "./Notification.scss"
import { Tolink } from '../../ToLink/Tolink';

const Notifications = () => {
    return (
        <div className="dropdown text-end">
            <div className="d-block link-white text-decoration-none notification-Icon" id="dropdownNotification" data-bs-toggle="dropdown" aria-expanded="false">
                <Icon classIcon='bell-fill' quantity={1} />
            </div>
            <ul className="dropdown-menu text-small" aria-labelledby="dropdownNotification">
                <ItemNotification src='https://github.com/mdo.png' time='hace 4 días'>
                    <strong>Jorge</strong> publicó un video.
                </ItemNotification>
                <ItemNotification src='https://github.com/mdo.png' time='hace 4 días'>
                    <strong>Jorge</strong> compartió la publicación de <strong>Yo Balderas</strong>
                </ItemNotification>
                <ItemNotification src='https://github.com/mdo.png' time='hace 4 días'>
                    <strong>Jorge</strong> recomienda tu producto  <strong>Playeras estampadas</strong>
                </ItemNotification>
                <li className='text-center'>
                    <Tolink to='/notificaciones' classlink='toLink-clr-principal'>Ver más</Tolink>
                </li>
            </ul>
        </div>
    );
};

export { Notifications };