import React from 'react';

interface Props {
    classIcon:string
    quantity?: number
}
const Icon:React.FC<Props> = ({classIcon = 'cart-fill', quantity=0 }) => {
    return (
        <div className='header-right-icon'>
            <i className={`bi bi-${classIcon}`}></i>
            {
                quantity !== 0 &&
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {quantity}
                </span>
            }
        </div>
    );
};

export {Icon};