import React from 'react';
import ReactDOM from 'react-dom/client';

// import {Header} from './Components/Header/index';

import { AppRoutes } from './Routesss';

import './Assets/Style/globales.css';
import './Assets/Style/Bootstrap_Styles.scss'


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AppRoutes />
  </React.StrictMode>
);
