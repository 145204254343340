import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Signin.scss';

import logo from '../../Assets/Image/Logo/logo1.png';
interface Props {
    // Define any props here if needed
}

const Signin: React.FC<Props> = () => {

/* The code snippet you provided is setting up multiple state variables using the `useState` hook in a
React functional component. Here's a breakdown of what each line is doing: */
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');

/* The code snippet you provided is defining regular expressions (`emailRegex` and `passwordRegex`) to
validate email and password inputs in a React functional component. Here's a breakdown of what each
regular expression is doing: */
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

/**
 * The handleSubmit function validates email and password inputs and displays error messages if they do
 * not meet the specified criteria.
 * @param e - The parameter `e` in the `handleSubmit` function is of type `React.FormEvent`, which is a
 * generic type representing events on form elements. In this case, it is used to handle form
 * submission events in a React component. The function prevents the default form submission behavior,
 * validates the email and
 */
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!emailRegex.test(email)) {
            setEmailError('Por favor ingresa un correo electrónico válido');
        } else {
            setEmailError('');
        }
        if (!passwordRegex.test(password)) {
            setPasswordError('La contraseña debe tener al menos 8 caracteres, incluyendo al menos una mayúscula, una minúscula y un número');
        } else {
            setPasswordError('');
        }
        if (emailRegex.test(email) && passwordRegex.test(password)) {
            console.log(`Email: ${email}, Password: ${password}`);
        }
    };

    const handleGoogleSignIn = () => {
        // Agrega aquí la lógica para iniciar sesión con Google
        console.log('Iniciar sesión con Google');
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="signin-container">
            <div className="login-form">
                <div className="text-center mb-4">
                    <img src={logo} alt="Clisvi" className="logo mb-4" />
                    <h2 className="text- #E28000">Iniciar sesión</h2>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Correo electrónico</label>
                        <input
                            type="email"
                            className={`form-control ${emailError && 'is-invalid'}`}
                            id="email"
                            placeholder="Correo electrónico"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                            required
                        />
                        {emailError && <div className="invalid-feedback">{emailError}</div>}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Contraseña</label>
                        <div className="input-group">
                            <input
                                type={showPassword ? "text" : "password"}
                                className={`form-control ${passwordError && 'is-invalid'}`}
                                id="password"
                                placeholder="Contraseña"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}"
                                required
                            />
                            <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                            </button>
                        </div>
                        {passwordError && <div className="invalid-feedback">{passwordError}</div>}
                    </div>
                    <div className="mb-3">
                        <div className="d-grid">
                            <button type="submit" className="btn btn-primary">Iniciar sesión</button>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="d-grid">
                            <button type="button" className="btn btn-google" onClick={handleGoogleSignIn}>
                                <i className="fab fa-google me-2"></i>Iniciar sesión con Google
                            </button>
                        </div>
                    </div>
                    <div className="text-center">
                        <Link to="#" className="link">¿Olvidaste tu contraseña?</Link>
                    </div>
                    <div className="text-center mt-3">
                        <span>¿No tienes una cuenta? </span>
                        <Link to="/register" className="link">Crear cuenta</Link>
                    </div>
                </form>
            </div>
        </div>

    );
};

export { Signin };
