import React from 'react';
import './StoreProducts.scss'

import { VideoProductItem } from '../../Components/VideoProductItem/VideoInfoItem';
import { OnlyVideoItem } from '../../Components/OnlyVideoItem/OnlyVideoItem';
import { DetailProduct } from '../../Components/VideoProductItem/DetailProduct';

const StoreProducts = () => {
    return (
        <div className="ShopProducts d-flex align-items-start">
            <aside className='ShopProducts-nav'>
                <h2><strong>Productos</strong> <span>8</span></h2>
                <div>
                    <div>
                        <h4>Categorías</h4>
                        <ul>
                            <li>Celulares y telefonía (25)</li>
                            <li>Electronica, Audio y Video (25)</li>
                        </ul>
                    </div>
                    <div>
                        <h4>Precio</h4>
                        <ul>
                            <li>Hasta $650 (8)</li>
                            <li>$650 a $3,000 (11)</li>
                            <li>Más de $3,000 (15)</li>
                        </ul>
                    </div>
                </div>
            </aside>
            <main className='ShopProducts-body'>
                {/* <ListClips /> */}
                <div className='ShopProducts-body__products'>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={false} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7507964/pexels-photo-7507964.jpeg?auto=compress&cs=tinysrgb&w=600'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={false} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/12339129/pexels-photo-12339129.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={false} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/14447086/pexels-photo-14447086.jpeg?auto=compress&cs=tinysrgb&w=600'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={false} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/8373959/pexels-photo-8373959.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={false} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/8368970/pexels-photo-8368970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={false} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={false} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7507964/pexels-photo-7507964.jpeg?auto=compress&cs=tinysrgb&w=600'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={false} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                </div>
            </main>
        </div>
    );
};

export { StoreProducts };