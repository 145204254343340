import React, { ReactNode, FC } from 'react';
import { Link } from 'react-router-dom';

import './Tolink.css'

type Props = {
    to: string,
    classlink?: string,
    children: ReactNode
}

const Tolink: FC<Props> = ({ to = '#', classlink = "toLink-clr-white", children }) => {
    return (
        <Link to={to} className={`Tolink decoration-none ${classlink}`}>
            {children}
        </Link>
    );
};

export { Tolink };


