import React, {FC, ReactNode} from 'react';
import { Tolink } from '../../ToLink/Tolink';

interface Props {
    url:string,
    children?:ReactNode
}

const NavItem:FC<Props> = ({url, children}) => {
    return (
        <li className='nav-menu-item'>
            <Tolink to={url} classlink='nav-menu-link link-nav'>
                {children}
            </Tolink>
        </li>
    );
};

export {NavItem};