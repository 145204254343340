import React from 'react';
import './Product.css'
import { SocialReactionsIndex } from '../../Containers/SocialReactionsIndex/SocialReactionsIndex';
import { Stars } from '../../Components/Stars/Stars';
import { Tolink } from '../../Components/ToLink/Tolink';
import { InfoUser } from '../../Components/InfoUser/InfoUser';
import { ProductVariations } from '../../Components/ProductVariations/ProductVariations';
import { SectionCarrucel } from "../../Containers/SectionCarrucel/SectionCarrucel";
import { VideoProductItem } from "../../Components/VideoProductItem/VideoInfoItem";
import { OnlyVideoItem } from '../../Components/OnlyVideoItem/OnlyVideoItem';
import { DetailProduct } from '../../Components/VideoProductItem/DetailProduct';
import { VideoInfoItem } from '../../Components/VideoInfoItem/VideoInfoItem';
import { DescriptionVideo } from '../../Components/VideoInfoItem/DescriptionVideo';
import { SellerInformation } from '../../Containers/SellerInformation/SellerInformation';

const Product = () => {
    document.title = "Xiaomi Redmi";

    return (
        <main className='Product'>
            <section className='Product-info'>
                <article className='video-social'>
                    <SocialReactionsIndex />
                </article>
                <article className='Product-property'>
                    <p>Nuevo | +2K vendidos</p>
                    <h1>Xiaomi Redmi 12 Dual SIM 128 GB azul océano 4 GB RAM</h1>
                    <div className='d-flex'>
                        <Tolink to='/product/score/34535-343fafsfasdfc' classlink='toLink-black_hover'>
                            <Stars score={3.2} showTotal={true} ratings={2563} />
                        </Tolink>
                    </div>
                    <div className="column-best-seller">
                        <span>
                            <Tolink to={'/mas-vendidos?categoria=telefonos'} classlink=''>
                                <span className="best-seller">#10 En ventas</span>
                            </Tolink>
                            &nbsp;
                        </span>
                    </div>

                    <ProductVariations />

                    <div className='product-details'>
                        <div className='product-details-title'>
                            <h3>
                                Acerca del producto
                            </h3>
                            <Tolink to={'#Description_product'} classlink='toLink-clr-secundario'>
                                <span className='product-details-link'>Descripción del producto</span>
                            </Tolink>
                        </div>

                        <ul>
                            <li>Dispositivo desbloqueado para que elijas la compañía telefónica que prefieras.</li>
                            <li>Pantalla IPS de 6.71".</li>
                            <li>Tiene 2 cámaras traseras de 50Mpx/2Mpx.</li>
                            <li>Cámara delantera de 5Mpx.</li>
                            <li>Procesador Snapdragon 680 Octa-Core de 2.4GHz con 4GB de RAM.</li>
                            <li>Batería de 5000mAh.</li>
                            <li>Memoria interna de 64GB.</li>
                            <li>Con reconocimiento facial y sensor de huella dactilar.</li>
                        </ul>
                    </div>
                </article>
                <article className='Product-seller'>
                    <SellerInformation />
                </article>
            </section>

            <SectionCarrucel
                title="Promocionados"
                textlink="Anunciate aquí"
                url="/anunciarme"
            >
                <VideoProductItem>
                    <OnlyVideoItem
                        linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                        linkVideoView='/product/34535-343fafsfasdfc'
                    >
                        <DetailProduct publicity={true} linkDetail='/product/34535-343fafsfasdfc00' />
                    </OnlyVideoItem>
                </VideoProductItem>
                <VideoProductItem>
                    <OnlyVideoItem
                        linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                        linkVideoView='/product/7283157820948614406'
                    >
                        <DetailProduct publicity={true} linkDetail='/product/34535-343fafsfasdfc' />
                    </OnlyVideoItem>
                </VideoProductItem>
                <VideoProductItem>
                    <OnlyVideoItem
                        linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                        linkVideoView='/product/7283157820948614406'
                    >
                        <DetailProduct publicity={true} linkDetail='/product/34535-343fafsfasdfc' />
                    </OnlyVideoItem>
                </VideoProductItem>
                <VideoProductItem>
                    <OnlyVideoItem
                        linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                        linkVideoView='/product/7283157820948614406'
                    >
                        <DetailProduct publicity={true} linkDetail='/product/34535-343fafsfasdfc' />
                    </OnlyVideoItem>
                </VideoProductItem>
                <VideoProductItem>
                    <OnlyVideoItem
                        linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                        linkVideoView='/product/7283157820948614406'
                    >
                        <DetailProduct publicity={true} linkDetail='/product/34535-343fafsfasdfc' />
                    </OnlyVideoItem>
                </VideoProductItem>
                <VideoProductItem>
                    <OnlyVideoItem
                        linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                        linkVideoView='/product/7283157820948614406'
                    >
                        <DetailProduct publicity={true} linkDetail='/product/34535-343fafsfasdfc' />
                    </OnlyVideoItem>
                </VideoProductItem>
                <VideoProductItem>
                    <OnlyVideoItem
                        linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                        linkVideoView='/product/7283157820948614406'
                    >
                        <DetailProduct publicity={true} linkDetail='/product/34535-343fafsfasdfc' />
                    </OnlyVideoItem>
                </VideoProductItem>
                <VideoProductItem>
                    <OnlyVideoItem
                        linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                        linkVideoView='/product/7283157820948614406'
                    >
                        <DetailProduct publicity={true} linkDetail='/product/34535-343fafsfasdfc' />
                    </OnlyVideoItem>
                </VideoProductItem>
                <VideoProductItem>
                    <OnlyVideoItem
                        linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                        linkVideoView='/product/7283157820948614406'
                    >
                        <DetailProduct publicity={true} linkDetail='/product/34535-343fafsfasdfc' />
                    </OnlyVideoItem>
                </VideoProductItem>
            </SectionCarrucel>

            <section id="Description_product" className='Product-detail'>
                <h1>Detalle Producto</h1>
            </section>
            <SectionCarrucel title="Reclips" textlink="Ver Clips" url="/reclips/product?re=7283157820948614406" >
                <VideoInfoItem>
                    <InfoUser
                        imgProfile='https://github.com/mdo.png'
                        name='Jorge Prueba'
                        link='/usuario/jorge1235'
                        showVerify={false}
                        user=""
                    >
                        <span>Hace 1h</span>
                    </InfoUser>
                    <div className="clip-column__video">
                        <OnlyVideoItem
                            linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                            linkVideoView='/reclips/product?re=7283157820948614406' >
                            <DescriptionVideo
                                linkVideoView='/reclips/product?re=7283157820948614406'
                                description=' En este video, te traemos la reseña completa del nuevo Smartphone XYZ. '
                                listTag={['características', 'Smartphone XYZ']} />
                        </OnlyVideoItem>
                    </div>
                </VideoInfoItem>
                <VideoInfoItem>
                    <InfoUser
                        imgProfile='https://github.com/mdo.png'
                        name='Jorge Prueba'
                        link='/usuario/jorge1235'
                        showVerify={false}
                        user=""
                    >
                        <span>Hace 1h</span>
                    </InfoUser>
                    <div className="clip-column__video">
                        <OnlyVideoItem
                            linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                            linkVideoView='/reclips/product?re=7283157820948614406' >
                            <DescriptionVideo
                                linkVideoView='/reclips/product?re=7283157820948614406'
                                description=' En este video, te traemos la reseña completa del nuevo Smartphone XYZ. '
                                listTag={['características', 'Smartphone XYZ']} />
                        </OnlyVideoItem>
                    </div>
                </VideoInfoItem>
                <VideoInfoItem>
                    <InfoUser
                        imgProfile='https://github.com/mdo.png'
                        name='Jorge Prueba'
                        link='/usuario/jorge1235'
                        showVerify={false}
                        user=""
                    >
                        <span>Hace 1h</span>
                    </InfoUser>
                    <div className="clip-column__video">
                        <OnlyVideoItem
                            linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                            linkVideoView='/reclips/product?re=7283157820948614406' >
                            <DescriptionVideo
                                linkVideoView='/reclips/product?re=7283157820948614406'
                                description=' En este video, te traemos la reseña completa del nuevo Smartphone XYZ. '
                                listTag={['características', 'Smartphone XYZ']} />
                        </OnlyVideoItem>
                    </div>
                </VideoInfoItem>
                <VideoInfoItem>
                    <InfoUser
                        imgProfile='https://github.com/mdo.png'
                        name='Jorge Prueba'
                        link='/usuario/jorge1235'
                        showVerify={false}
                        user=""
                    >
                        <span>Hace 1h</span>
                    </InfoUser>
                    <div className="clip-column__video">
                        <OnlyVideoItem
                            linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                            linkVideoView='/reclips/product?re=7283157820948614406' >
                            <DescriptionVideo
                                linkVideoView='/reclips/product?re=7283157820948614406'
                                description=' En este video, te traemos la reseña completa del nuevo Smartphone XYZ. '
                                listTag={['características', 'Smartphone XYZ']} />
                        </OnlyVideoItem>
                    </div>
                </VideoInfoItem>
                <VideoInfoItem>
                    <InfoUser
                        imgProfile='https://github.com/mdo.png'
                        name='Jorge Prueba'
                        link='/usuario/jorge1235'
                        showVerify={false}
                        user=""
                    >
                        <span>Hace 1h</span>
                    </InfoUser>
                    <div className="clip-column__video">
                        <OnlyVideoItem
                            linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                            linkVideoView='/reclips/product?re=7283157820948614406' >
                            <DescriptionVideo
                                linkVideoView='/reclips/product?re=7283157820948614406'
                                description=' En este video, te traemos la reseña completa del nuevo Smartphone XYZ. '
                                listTag={['características', 'Smartphone XYZ']} />
                        </OnlyVideoItem>
                    </div>
                </VideoInfoItem>
                <VideoInfoItem>
                    <InfoUser
                        imgProfile='https://github.com/mdo.png'
                        name='Jorge Prueba'
                        link='/usuario/jorge1235'
                        showVerify={false}
                        user=""
                    >
                        <span>Hace 1h</span>
                    </InfoUser>
                    <div className="clip-column__video">
                        <OnlyVideoItem
                            linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                            linkVideoView='/reclips/product?re=7283157820948614406' >
                            <DescriptionVideo
                                linkVideoView='/reclips/product?re=7283157820948614406'
                                description=' En este video, te traemos la reseña completa del nuevo Smartphone XYZ. '
                                listTag={['características', 'Smartphone XYZ']} />
                        </OnlyVideoItem>
                    </div>
                </VideoInfoItem>
                
            </SectionCarrucel>

            <section id="compare_product" className='Product-detail'>
                <h1>Compara con otros productos</h1>
            </section>

            <SectionCarrucel
                title="Productos relacionados"
                textlink="Ver más"
                url="/masvendidos"
            >
                <VideoProductItem>
                    <OnlyVideoItem
                        linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                        linkVideoView='/product/7283157820948614406'

                    >
                        <DetailProduct publicity={false} linkDetail='/product/34535-343fafsfasdfc' />
                    </OnlyVideoItem>
                </VideoProductItem>
                <VideoProductItem>
                    <OnlyVideoItem
                        linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                        linkVideoView='/product/7283157820948614406'
                    >
                        <DetailProduct publicity={false} linkDetail='/product/34535-343fafsfasdfc' />
                    </OnlyVideoItem>
                </VideoProductItem>
                <VideoProductItem>
                    <OnlyVideoItem
                        linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                        linkVideoView='/product/7283157820948614406'
                    >
                        <DetailProduct publicity={false} linkDetail='/product/34535-343fafsfasdfc' />
                    </OnlyVideoItem>
                </VideoProductItem>
                <VideoProductItem>
                    <OnlyVideoItem
                        linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                        linkVideoView='/product/7283157820948614406'
                    >
                        <DetailProduct publicity={false} linkDetail='/product/34535-343fafsfasdfc' />
                    </OnlyVideoItem>
                </VideoProductItem>
                <VideoProductItem>
                    <OnlyVideoItem
                        linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                        linkVideoView='/product/7283157820948614406'
                    >
                        <DetailProduct publicity={false} linkDetail='/product/34535-343fafsfasdfc' />
                    </OnlyVideoItem>
                </VideoProductItem>
                <VideoProductItem>
                    <OnlyVideoItem
                        linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                        linkVideoView='/product/7283157820948614406'
                    >
                        <DetailProduct publicity={false} linkDetail='/product/34535-343fafsfasdfc' />
                    </OnlyVideoItem>
                </VideoProductItem>
                <VideoProductItem>
                    <OnlyVideoItem
                        linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                        linkVideoView='/product/7283157820948614406'
                    >
                        <DetailProduct publicity={false} linkDetail='/product/34535-343fafsfasdfc' />
                    </OnlyVideoItem>
                </VideoProductItem>
                <VideoProductItem>
                    <OnlyVideoItem
                        linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                        linkVideoView='/product/7283157820948614406'
                    >
                        <DetailProduct publicity={false} linkDetail='/product/34535-343fafsfasdfc' />
                    </OnlyVideoItem>
                </VideoProductItem>
            </SectionCarrucel>


            <section id="Description_product" className='Product-detail'>

            </section>
        </main>
    );
};

export { Product };