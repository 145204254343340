import React from 'react';
import './OnlyVideo.scss'

import { Video } from '../Video/Video';
import { VideoPlayer } from '../VideoPlayer/VideoPlayer';

// import video from './videoxaomi.mp4'

const OnlyVideo = () => {
    return (
        <div className="css-1tunefa-DivVideoContainer e11s2kul23">
            <div className="css-zqqb1u-DivSearchBarContainer e11s2kul27">
                <div className="css-1vve6pb-DivSearchBarBackground e11s2kul28"></div>
            </div>

            <div className="css-15ggvmu-DivVideoWrapper e11s2kul4">
                <div className="css-1jxhpnd-DivContainer e1yey0rl0 VideoPlayer_visible">
                    <VideoPlayer modelight={false} linkToPage='' />
                </div>
            </div>

            <div role="button" aria-label="Denunciar" id="browser-mode-report-btn" data-e2e="browse-report" className="css-pj2fot-DivReportText e11s2kul17">
                <i className="bi bi-heart-fill"></i>
            </div>

        </div>
    );
};

export { OnlyVideo };