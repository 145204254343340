import React from 'react';
import './InfoUser.scss'

import { Tolink } from '../ToLink/Tolink';

interface Props {
    children?: React.ReactNode
    imgProfile: string
    name: string
    link: string
    showVerify: boolean
    showUser?: boolean
    user: string
}
const InfoUser: React.FC<Props> = ({ children, imgProfile, name, link, showVerify, showUser = false, user }) => {
    return (
        <div className='card-column__video--infoUser'>
            <Tolink to={link} classlink='link-black '>
                <div className='video-infoUser--photo'>
                    <img src={imgProfile} alt="" sizes="" />
                </div>
            </Tolink>
            <div className='video-infoUser--data'>
                <Tolink to={link} classlink='link-black '>
                    <span className='video-infoUser--name'>{name}</span>
                </Tolink>
                {
                    showVerify &&
                    <span className='video-infoUser--verify'><i className="bi bi-patch-check-fill"></i></span>

                }
                {
                    showUser &&
                    <div className='video-infoUser--user'>@{user}</div>
                }
                <div className='video-infoUser--add'>
                    {children}
                </div>
            </div>
        </div>
    );
};

export { InfoUser };