import React from 'react';

const NotFound = () => {
    document.title = '404 NotFound';
    return (
        <div>
            Error 404 NotFound
        </div>
    );
};

export { NotFound };