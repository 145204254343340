import React from 'react';
import { useNavigate } from 'react-router-dom';
import './VideoPlayer.scss'

import ReactPlayer from 'react-player';
import Duration from './Duracion'

const VideoPlayer = ({
    modelight,
    urlVideo = 'https://clisvi.com/Videos/publicacion.mp4',
    urlImglight = 'https://images.pexels.com/photos/3791466/pexels-photo-3791466.jpeg?auto=compress&cs=tinysrgb&w=600',
    linkToPage
}) => {
    let navigate = useNavigate();
    const [showControls, setshowControls] = React.useState(!modelight)
    const [padlockVideo, setPadlockVideo] = React.useState(false)
    const [modeLight] = React.useState(modelight)
    const [dataControlls, setDataControlls] = React.useState(
        {
            url: urlVideo,
            pip: false,
            playing: false,
            controls: false,
            light: modelight,
            volume: 0,
            muted: true,
            played: 0,
            loaded: 0,
            duration: 0,
            playbackRate: 1.0,
            loop: true,
            seeking: false
        }
    )

    const load = url => {
        setDataControlls({
            ...dataControlls,
            url,
            played: 0,
            loaded: 0,
            pip: false
        })
    }

    // const { url, playing, controls, light, volume, muted, loop, played, loaded, duration, playbackRate, pip } = dataControlls;

    const handlePlayPause = () => {
        if (dataControlls.muted) {
            setDataControlls({ ...dataControlls, playing: !dataControlls.playing, volume: parseFloat(1), muted: false })
        } else {
            setDataControlls({ ...dataControlls, playing: !dataControlls.playing })
        }

        if (modelight) {
            if (!padlockVideo) {
                setPadlockVideo(true)
                setDataControlls({ ...dataControlls, playing: true })
            }
        }
    }

    const handleCa = () => {
        setDataControlls({ ...dataControlls, playing: !dataControlls.playing })
    }

    const handleLinkToPage = () => {
        navigate(linkToPage);
    }
    const handleMouseEnter = () => {
        setDataControlls({ ...dataControlls, playing: true })
    }
    const handleMouseLeave = () => {
        setDataControlls({ ...dataControlls, playing: false })
    }

    const handleStop = () => {
        setDataControlls({ ...dataControlls, url: null, playing: false })
    }

    const handleToggleControls = () => {
        const url = dataControlls.url
        setDataControlls({
            ...dataControlls,
            controls: !dataControlls.controls,
            url: null
        }, () => load(url))
    }

    const handleToggleLight = () => {
        setDataControlls({ ...dataControlls, light: !dataControlls.light })
    }

    const handleToggleLoop = () => {
        setDataControlls({ ...dataControlls, loop: !dataControlls.loop })
    }

    const handleVolumeChange = e => {

        if (parseFloat(e.target.value) !== 0) {
            //con volumen
            setDataControlls({ ...dataControlls, muted: false, volume: parseFloat(e.target.value) })
        } else {
            //sin volumen
            setDataControlls({ ...dataControlls, muted: true, volume: 0 })
        }
    }

    const handleToggleMuted = () => {
        if (dataControlls.muted) {
            //entra true pero sale false
            setDataControlls({ ...dataControlls, muted: !dataControlls.muted, volume: dataControlls.volume })
        } else {
            //entra false pero sale true
            setDataControlls({ ...dataControlls, muted: !dataControlls.muted, volume: 0 })
        }
    }

    const handleSetPlaybackRate = e => {
        setDataControlls({ ...dataControlls, playbackRate: parseFloat(e.target.value) })
    }

    const handleTogglePIP = () => {
        setDataControlls({ ...dataControlls, pip: !dataControlls.pip })
    }

    const handlePlay = () => {
        //console.log('onPlay')
        setDataControlls({ ...dataControlls, playing: true })
    }

    const handleEnablePIP = () => {
        //console.log('onEnablePIP')
        setDataControlls({ ...dataControlls, pip: true })
    }

    const handleDisablePIP = () => {
        //console.log('onDisablePIP')
        setDataControlls({ ...dataControlls, pip: false })
    }

    const handlePause = () => {
        //console.log('onPause')
        setDataControlls({ ...dataControlls, playing: false })
    }

    const handleSeekMouseDown = e => {
        setDataControlls({ ...dataControlls, seeking: true })
    }

    const handleSeekChange = e => {
        setDataControlls({ ...dataControlls, played: parseFloat(e.target.value) })
    }

    const handleSeekMouseUp = e => {
        setDataControlls({ ...dataControlls, seeking: false })
        dataControlls.player.seekTo(parseFloat(e.target.value))
    }

    const handleProgress = state => {
        //console.log('onProgress', state)        
        // We only want to update time slider if we are not currently seeking
        if (!dataControlls.seeking) {
            setDataControlls({ ...dataControlls, ...state })
        }
    }

    const handleEnded = () => {
        //console.log('onEnded')
        setDataControlls({ ...dataControlls, playing: dataControlls.loop })
    }

    const handleDuration = (duration) => {
        //console.log('onDuration', duration)
        setDataControlls({ ...dataControlls, duration })
    }

    const ref = player => {
        dataControlls.player = player
    }

    const handleShowControls = () => {
        setshowControls(true)
    }
    const handleHideControls = () => {
        setshowControls(false)
    }

    React.useEffect(() => {
        if (modeLight) {
            setDataControlls({ ...dataControlls, playing: modelight })
        }
    }, [modeLight])

    return (
        <div className='VideoPlayer'>
            {/* onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} */}
            <div className='video_player' onClick={handlePlayPause}  >

                <ReactPlayer
                    ref={ref}
                    className='react-player'
                    width='100%'
                    height='100%'
                    url={dataControlls.url}
                    pip={false}
                    playing={dataControlls.playing}
                    controls={dataControlls.controls}
                    light={modeLight ? <img src={urlImglight} alt="playIcon" /> : modelight}
                    loop={dataControlls.loop}
                    playbackRate={dataControlls.playbackRate}
                    volume={dataControlls.volume}
                    muted={dataControlls.muted}
                    onPlay={handlePlay}
                    onEnablePIP={handleEnablePIP}
                    onDisablePIP={handleDisablePIP}
                    onPause={handlePause}
                    onEnded={handleEnded}
                    onProgress={handleProgress}
                    onDuration={handleDuration}

                    onReady={handleMouseEnter}
                    onStart={handleShowControls}
                    onBuffer={() => console.log('onBuffer')}
                    onSeek={e => console.log('onSeek', e)}
                    onError={e => console.log('onError', e)}
                />
            </div>
            {
                padlockVideo &&
                <div onClick={handleLinkToPage} className='video_player__window'></div>
            }

            {
                showControls &&
                <div className='video_player-autoControls'>
                    <div className='autoControls-content'>
                        <div className='autoControls-controls'>
                            <div role="button" aria-label="Reproducir" aria-pressed="true" data-e2e="video-play"
                                className="video_player-actionControls">
                                <div className='video_player__time'>
                                    <div className='autoControls-btn'>
                                        <span className='autoControls-btn--color'>
                                            {dataControlls.playing ?
                                                <i onClick={handlePlayPause} className="bi bi-pause-fill"></i> :
                                                <i onClick={handlePlayPause} className="bi bi-play-fill"></i>
                                            }
                                        </span>
                                    </div>
                                    <div className='div_time'>
                                        <span className='video_player--time'>
                                            <Duration seconds={dataControlls.duration * dataControlls.played} /> / <Duration seconds={dataControlls.duration} />
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <div>
                                <div className='autoControls-controls-volume autoControls--volume autoControls-btn'>
                                    <span className='autoControls-btn--color'>
                                        {
                                            dataControlls.muted ?
                                                <i className="bi bi-volume-mute-fill" onClick={handleToggleMuted}></i>
                                                :
                                                <i className="bi bi-volume-up-fill" onClick={handleToggleMuted}></i>
                                        }
                                    </span>
                                    <input className='controls-volume' type='range' min={0} max={1} step='any'
                                        value={dataControlls.volume}
                                        onChange={handleVolumeChange} />
                                </div>
                            </div>
                        </div>
                        <div className='autoControls-progress'>
                            <progress className='progress--content w-100' max={1} value="100" />
                            <progress className='progress--line w-100' max={1} value={dataControlls.played} />
                            <progress className='progress--loaded w-100' max={1} value={dataControlls.loaded} />
                            <br />
                            <input
                                className='progress--Seek w-100'
                                type='range' min={0} max={1} step='any'
                                value={dataControlls.played}
                                onMouseDown={handleSeekMouseDown}
                                onChange={handleSeekChange}
                                onMouseUp={handleSeekMouseUp}
                            />
                        </div>

                    </div>
                </div>
            }
        </div>
    );
};

export { VideoPlayer };