import React from 'react';
import './InformationStore.scss'

import { Info } from './Info';
import { VideoProductItem } from '../../Components/VideoProductItem/VideoInfoItem';
import { OnlyVideoItem } from '../../Components/OnlyVideoItem/OnlyVideoItem';
import { DetailProduct } from '../../Components/VideoProductItem/DetailProduct';
import { ScoreComments } from '../ScoreComments/ScoreComments';


const InformationStore = () => {
    return (
        <div className='InformationStore'>
            <main className='InformationStore-info'>
                <Info />
                <ScoreComments/>
            </main>
            <aside className='InformationStore-publicity'>
                <div className='InformationStore-publicity-content'>
                    <div className='InformationStore-publicity--title'>
                        Publicidad
                    </div>
                    <div className='InformationStore-publicity-item'>
                        <VideoProductItem>
                            <OnlyVideoItem
                                linkImg='https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/ochedFsEEIxEQmSonKfzPOzARL7rlDJmBBFkBK~tplv-photomode-zoomcover:720:720.avif?x-expires=1701615600&x-signature=m3Ea7Cewv%2B%2BFVo7VZouKMrhib14%3D'
                                linkVideoView='/product/7283157820948614406'
                            >
                                <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                            </OnlyVideoItem>
                        </VideoProductItem>
                    </div>
                </div>
            </aside>
        </div>
    );
};

export { InformationStore };