import React from 'react';
import './StoreInfoItem.scss'
import { Button } from '../Button/Button';
import { Stars } from '../Stars/Stars';
import { Tolink } from '../ToLink/Tolink';

interface Props {
    imagePortada: string
    imageProfile: string
    nameProfile: string
    userProfile: string

}


const StoreCarouselItem: React.FC<Props> = ({ imagePortada, imageProfile, nameProfile, userProfile }) => {
    return (
        <>
            <div role="group" className="andes-carousel-snapped__slide" aria-label="1 de 12" data-slider="0">
                <div className="ui-card official-stores-slide is-link" role="link">
                    <Tolink to={`/store/${userProfile}`}>
                        <div className="official-stores-slide-background">
                            <img decoding="async" src={imagePortada} alt="Andrea" />
                        </div>
                        <div className="official-stores-slide-logo">
                            <img width="80" height="80" decoding="async" src={imageProfile} alt="Andrea" />
                        </div>
                    </Tolink>
                    <div className='stores-wrapper-follow'>
                        <Button classBtn='button_secundario'>Seguir</Button>
                    </div>
                    <Tolink to={`/store/${userProfile}`}>
                        <div className="title-wrapper">
                            <div className="official-stores-slide-title">{nameProfile}</div>
                            <div className="official-stores-slide-user">@{userProfile}</div>
                        </div>
                    </Tolink>

                    <div className="official-stores-slide-order-wrapper">
                        <div className="stores-slide-items ">
                            <div className='info-stores-slide'>
                                <Stars score={2.5} showTotal={true} ratings={1253} />
                                <div className='info-stores-followers'>
                                    <span className='info-stores-followers-number'><strong>10 mil</strong> </span><span className='info-stores-followers-text'>Seguidores</span>
                                </div>
                                <div className='info-stores-followers'>
                                    <span className='info-stores-followers-text'>10K Ventas realizadas los ulttimos 90 días</span>
                                </div>

                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </>
    );
};

export { StoreCarouselItem };