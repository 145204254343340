import React from 'react';
import './Post.scss'
import clip from '../../Assets/Image/Post/clip.png'
import producto from '../../Assets/Image/Post/producto.png'
import reclip from '../../Assets/Image/Post/reclip.png'
import afiliacion from '../../Assets/Image/Post/afiliacion.png'

const Post = () => {
    return (
        <section className='Post'>
            <div className='Post-content'>  
                <div className='Post-content__options'>
                    <div>
                        <h2 className='Post-title'>Publicar</h2>
                        <div>
                            <span>¿Que publicación quieres hacer?</span>
                        </div>
                    </div>
                    <div className='Post-content-allOpcions'>
                        <div className='Post-item'>
                            <div className='Post-item__content'>
                                <h3>Clip</h3>
                                <div className='Post-item_contImg'>
                                    <img src={clip} alt="" srcSet="" />
                                    <p>
                                        <span>
                                            Comparte videos y crea tu comunidad.
                                        </span>
                                    </p>
                                </div>
                                {/* MP4 o WebM */}
                            </div>
                        </div>
                        <div className='Post-item'>
                            <div className='Post-item__content'>
                                <h3>Reclip</h3>
                                <div className='Post-item_contImg'>
                                    <img src={reclip} alt="" srcSet="" />
                                    <p>
                                        <span>
                                            Comparte experiencias.
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='Post-item'>
                            <div className='Post-item__content'>
                                <h3>Nuevo producto</h3>
                                <div className='Post-item_contImg'>
                                    <img src={producto} alt="" srcSet="" />
                                    <p>
                                        <span>
                                            Genera ingresos vendiendo productos.
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='Post-item'>
                            <div className='Post-item__content'>
                                <h3>Afiliación</h3>
                                <div className='Post-item_contImg'>
                                    <img src={afiliacion} alt="" srcSet="" />
                                    <p>
                                        <span>
                                            Genera ganancias de los productos afiliados.
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                            
            </div>            
        </section>
    );
};

export {Post};