import React from 'react';
import './ProductScore.scss'

import { Tolink } from '../../Components/ToLink/Tolink';
import { SellerInformation } from '../../Containers/SellerInformation/SellerInformation';
import { SectionCarrucel } from '../../Containers/SectionCarrucel/SectionCarrucel';
import { OnlyVideoItem } from '../../Components/OnlyVideoItem/OnlyVideoItem';
import { VideoInfoItem } from '../../Components/VideoInfoItem/VideoInfoItem';
import { InfoUser } from '../../Components/InfoUser/InfoUser';
import { DescriptionVideo } from '../../Components/VideoInfoItem/DescriptionVideo';
import { ScoreComments } from '../../Containers/ScoreComments/ScoreComments';
import { Button } from '../../Components/Button/Button';

const ProductScore = () => {
    document.title = "Score Xiaomi Redmi";
    return (
        <section className='ProductScore'>
            <div className='ProductScore-container'>
                <div className='ProductScore-score'>
                    <div className='ProductScore-header__container'>
                        <div className='ProductScore-header__container'>
                            <div className='ProductScore__title'>
                                <p>Nuevo</p>
                                <Tolink to='/product/34535-343fafsfasdfc' classlink='toLink'>
                                    <h1>Xiaomi Redmi 12 Dual SIM 128 GB azul océano 4 GB RAM</h1>
                                </Tolink>
                            </div>
                        </div>
                    </div>
                    <div className='ProductScore-body__container'>
                        <div className='ProductScore-info'>
                            <div className='ProductScore-info__head'>
                                <div className='ProductScore-info__head--title'>
                                    <h3>Puntuación del producto</h3>
                                </div>
                                <div className='ProductScore-info__head--description'>
                                    <div className='ProductScore-info__head--assessment'>
                                        <span>Estadisticas generadas de los</span>
                                        &nbsp;
                                        <span>últmos 90 días</span>
                                    </div>
                                </div>
                            </div>
                            <div className='ProductScore-info__body'>
                                <div className='row'>
                                    <div className='col-4'>
                                        <div className="ProductScore-card card">
                                            <div className="card-body">
                                                <div className='card-content'>
                                                    <div className='card-container-info'>
                                                        <div className='card--value card-text'>
                                                            <h1>+ 2,000</h1>
                                                        </div>
                                                        <div className='card--description card-text'>
                                                            <span>Vendidos</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="ProductScore-card card">
                                            <div className='card-body'>
                                                <div className='card-content'>
                                                    <div className='card-container-info'>
                                                        <div className='card--value card-text'>
                                                            <h1>95%</h1>
                                                        </div>
                                                        <div className='card--description card-text'>
                                                            <span>Calificaciones positivas</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="ProductScore-card card">
                                            <div className="card-body">
                                                <div className='card-content'>
                                                    <div className='card-container-info'>
                                                        <div className='card--value card-text'>
                                                            <h1>15</h1>
                                                        </div>
                                                        <div className='card--description card-text'>
                                                            <span>Reclips</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='ProductScore-additional mt-5'>
                            <SectionCarrucel title="Reclips (15)" url="/reclips/product?re=7283157820948614406" >
                                <VideoInfoItem>
                                    <InfoUser
                                        imgProfile='https://github.com/mdo.png'
                                        name='Jorge Prueba'
                                        link='/usuario/jorge1235'
                                        showVerify={false}
                                        user=""
                                    >
                                        <span>Hace 1h</span>
                                    </InfoUser>
                                    <div className="clip-column__video">
                                        <OnlyVideoItem
                                            linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                                            linkVideoView='/reclips/product?re=7283157820948614406' >
                                            <DescriptionVideo
                                                linkVideoView='/reclips/product?re=7283157820948614406'
                                                description=' En este video, te traemos la reseña completa del nuevo Smartphone XYZ. '
                                                listTag={['características', 'Smartphone XYZ']} />
                                        </OnlyVideoItem>
                                    </div>
                                </VideoInfoItem>
                                <VideoInfoItem>
                                    <InfoUser
                                        imgProfile='https://github.com/mdo.png'
                                        name='Jorge Prueba'
                                        link='/usuario/jorge1235'
                                        showVerify={false}
                                        user=""
                                    >
                                        <span>Hace 1h</span>
                                    </InfoUser>
                                    <div className="clip-column__video">
                                        <OnlyVideoItem
                                            linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                                            linkVideoView='/reclips/product?re=7283157820948614406' >
                                            <DescriptionVideo
                                                linkVideoView='/reclips/product?re=7283157820948614406'
                                                description=' En este video, te traemos la reseña completa del nuevo Smartphone XYZ. '
                                                listTag={['características', 'Smartphone XYZ']} />
                                        </OnlyVideoItem>
                                    </div>
                                </VideoInfoItem>
                                <VideoInfoItem>
                                    <InfoUser
                                        imgProfile='https://github.com/mdo.png'
                                        name='Jorge Prueba'
                                        link='/usuario/jorge1235'
                                        showVerify={false}
                                        user=""
                                    >
                                        <span>Hace 1h</span>
                                    </InfoUser>
                                    <div className="clip-column__video">
                                        <OnlyVideoItem
                                            linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                                            linkVideoView='/reclips/product?re=7283157820948614406' >
                                            <DescriptionVideo
                                                linkVideoView='/reclips/product?re=7283157820948614406'
                                                description=' En este video, te traemos la reseña completa del nuevo Smartphone XYZ. '
                                                listTag={['características', 'Smartphone XYZ']} />
                                        </OnlyVideoItem>
                                    </div>
                                </VideoInfoItem>
                                <VideoInfoItem>
                                    <InfoUser
                                        imgProfile='https://github.com/mdo.png'
                                        name='Jorge Prueba'
                                        link='/usuario/jorge1235'
                                        showVerify={false}
                                        user=""
                                    >
                                        <span>Hace 1h</span>
                                    </InfoUser>
                                    <div className="clip-column__video">
                                        <OnlyVideoItem
                                            linkImg='https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/oExDZ1aeQvWCJfEBWLdnbBgVW6A8PivQxQUlgh?x-expires=1700928000&amp;x-signature=Jm08Cp16uQBWKyPSbri6LqvqPsk%3D'
                                            linkVideoView='/reclips/product?re=7283157820948614406' >
                                            <DescriptionVideo
                                                linkVideoView='/reclips/product?re=7283157820948614406'
                                                description=' En este video, te traemos la reseña completa del nuevo Smartphone XYZ. '
                                                listTag={['características', 'Smartphone XYZ']} />
                                        </OnlyVideoItem>
                                    </div>
                                </VideoInfoItem>

                            </SectionCarrucel>
                        </div>

                        <ScoreComments showNameProduct={true}/>
                    </div>
                </div>
                <div className='ProductScore-seller'>
                    <SellerInformation showButtons={false}>
                        <div className='Product-cont-bottons'>
                            <Tolink to='/product/34535-343fafsfasdfc' classlink='toLink'>
                                <Button classBtn='button_secundario active'>Ver producto</Button>
                            </Tolink>
                        </div>
                    </SellerInformation>
                </div>
            </div>

        </section>
    );
};

export { ProductScore };