import React from 'react';
import './ListGroupClips.scss'
import { GroupClipItem } from '../../Components/GroupClipItem/GroupClipItem';

const ListGroupClips = () => {
    return (
        <div className='ListGroupClips'>
            <GroupClipItem />
            <GroupClipItem />
            <GroupClipItem />
            <GroupClipItem />
            <GroupClipItem />
            <GroupClipItem />
            <GroupClipItem />
            <GroupClipItem />
            <GroupClipItem />
            <GroupClipItem />
            <GroupClipItem />
            <GroupClipItem />
            <GroupClipItem />
            <GroupClipItem />
            <GroupClipItem />
            <GroupClipItem />
            <GroupClipItem />
            <GroupClipItem />
            <GroupClipItem />
            <GroupClipItem />
            <GroupClipItem />
        </div>
    );
};

export { ListGroupClips };