import React from 'react';
import './MenuOptionsPublication.scss'

interface Props {
    children: React.ReactNode
}

const MenuOptionsPublication: React.FC<Props> = ({ children }) => {
    return (
        <div id="ember6087" className="publication-product-shared-control-menu-options dropstart">
            <button
                tabIndex={0}
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                className="colorless colorless__hover colorless-circle"
                aria-label="Abrir el menú de control"
                type="button"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots" viewBox="0 0 16 16">
                    <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                </svg>
            </button>
            <div
                aria-hidden="true"
                id="ember6089"
                className="publication-product-shared-control-menu__content dropdown-menu"
                aria-label="Opciones del menú de control"
                aria-labelledby="dropdownMenuButton1"
            >
                <ul className=''>
                    {children}
                </ul>
            </div>
        </div>
    );
};

export { MenuOptionsPublication };