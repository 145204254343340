import React from 'react';
import './VideoInfoItem.css'

interface Props {
    children: React.ReactNode
}

const VideoInfoItem: React.FC<Props> = ({ children }) => {
    return (
        <div role="group" className="VideoInfoItem">
            <div className="clip-column-cont">
                {children}
            </div>
        </div>
    );
};

export { VideoInfoItem };

//https://www.youtube.com/shorts/m6Li8D2QOsU?feature=share