import React from 'react';

interface Props {
    children: React.ReactNode
    name: string
    date: string
    comment: string
}

const CommentItem: React.FC<Props> = ({ children, name, date, comment }) => {
    return (
        <div className="card">
            <div className="card-body">
                <div className='comments-date-star'>
                    {children}
                    <div><span>Por {name} </span> <span>{date}</span></div>
                </div>
                <p>
                    {comment}
                </p>
            </div>
        </div>
    );
};

export { CommentItem };