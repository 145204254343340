import React from 'react';
import './InformationUser.scss'

const InformationUser = () => {
    return (
        <section className='InformationUser'>
            <div className='InformationUser-content'>
                <div className='row'>
                    <div className='col-6'>
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title">Información</h2>
                                <div className='iformation-c6'>
                                    <ul>
                                        <li><h5>Cuenta verificada</h5></li>
                                        <li><h5>2.4 M de suscriptores</h5></li>
                                        <li><h5>Se unio marzo 2023</h5></li>
                                        <li><h5>México</h5></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-6'>
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title">Clips</h2>
                                <div className='iformation-c6'>
                                    <ul>
                                        <li><h5>45 clips</h5></li>
                                        <li><h5>45,500 minutos de reproducción</h5></li>
                                        <li><h5>345 vistas</h5></li>
                                        <li><h5>10k Me gusta</h5></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title">Productos afiliados</h2>
                                <div className='iformation-c6'>
                                    <ul>
                                        <li><h5>45 productos afiliados</h5></li>
                                        <li><h5>Ha ayudado a realizar 434 ventas</h5></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export { InformationUser };