import React from 'react';
import './Reclips.scss'

import { OnlyVideo } from '../../Components/OnlyVideo/OnlyVideo';
import { Button } from '../../Components/Button/Button';
import { InfoUser } from '../../Components/InfoUser/InfoUser';
import { Stars } from '../../Components/Stars/Stars';
import { Reactions } from '../../Components/Reactions/Reactions';
import { OnlyVideoItem } from '../../Components/OnlyVideoItem/OnlyVideoItem';
import { DescriptionVideo } from '../../Components/VideoInfoItem/DescriptionVideo';
import { Tolink } from '../../Components/ToLink/Tolink';
import { Comments } from '../../Components/Comments/Comments';

const Reclips = () => {
    document.title = "reClips Xiaomi";

    React.useEffect(() => {
        console.log('Valor de la vista:');
    }, []);


    return (
        <>
            <div className='reclips-info_product'>
                <p>Nuevo | +2K vendidos</p>
                <Tolink to='/product/34535-343fafsfasdfc' classlink='toLink'>
                    <h1>Xiaomi Redmi 12 Dual SIM 128 GB azul océano 4 GB RAM</h1>
                </Tolink>
                <div className='d-flex'>
                    <Tolink to='/product/score/34535-343fafsfasdfc' classlink='toLink-black_hover'>
                        <Stars score={3.2} showTotal={true} ratings={2563} />
                    </Tolink>
                </div>
                <div className="column-best-seller">
                    <span>
                        <Tolink to={'/mas-vendidos?categoria=telefonos'} classlink=''>
                            <span className="best-seller">#10 En ventas</span>
                        </Tolink>
                        &nbsp;
                    </span>
                </div>
            </div>
            <div className='Reclips reclips-content'>
                <div className='reclips-video'>
                        
                    <div className='video-social--display'>
                        <OnlyVideo />
                    </div>
                    <div className='reclips-detail pt-3'>
                        <h4 className='ps-3'><strong>ReClip by</strong></h4>
                        <div className='reclips-detail__user-activity'>
                            <div className='reclips-detail__user'>
                                <div className='reclips-detail__user-info'>
                                    <InfoUser
                                        showVerify={true}
                                        name='Usuario'
                                        link='/usuario/Platzi'
                                        imgProfile='https://github.com/mdo.png'
                                        user=""
                                    >
                                        <span className="publication-actor__description">
                                            <span aria-hidden="true">205.781 seguidores</span>
                                            <span className="visually-hidden">205.781 seguidores</span>
                                        </span>
                                    </InfoUser>
                                    <Button classBtn='button_secundario ms-5'>Seguir</Button>
                                </div>

                            </div>
                            <div className='reclips-detail__activity'>
                                <div className='reclips-details--bar__container-reaccions'>
                                    <span role='group' className="social-details-bar__action--reaccions social-reactions-accion-button dropup">
                                        <Reactions classButton='social-details-reaccions__action--button social-bar-button'>
                                            <i className="bi bi-hand-thumbs-up"></i>
                                        </Reactions>
                                    </span>
                                    <span role='group' className="social-details-bar__action--reaccions reactions-react-button">
                                        <button type="button" className="social-details-reaccions__action--button social-bar-button">
                                            <i className="bi bi-repeat"></i>Compartir
                                        </button>
                                    </span>
                                    <span role='group' className="social-details-bar__action--reaccions reactions-react-button">
                                        <button type="button" className="social-details-reaccions__action--button social-bar-button">
                                            <i className="bi bi-collection-play-fill"></i>Guardar
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='reclips-detail__contentDescription'>      
                            
                            <div className="item-descrip-clip">
                                <span className="item-descrip-clip--SpanText">
                                    Descripcion del video
                                </span>
                                <br/>
                                {
                                    ['cocina', 'desinfectante'].map((tag) => (
                                        <a key={tag} href={`/tag/${tag}`} data-e2e="search-common-link" target="_self" rel="opener" className="item-descrip-clip--StyledCommonLink" aria-label="Watch more videos of the #fullerette category">
                                            <strong color="rgba(43, 93, 185, 1)" className="item-descrip-clip--StrongText">
                                                #{tag}
                                            </strong>
                                        </a>
                                    ))
                                }
                                <span className="item-descrip-clip--SpanText"></span>
                            </div>                
                            {/* <span className="break-words">
                                <span>
                                    <span dir="ltr">
                                        Tips para la señora que todas llevamos dentro, descubre el potencial del neutralizador azul fresh#cocina#desinfectante
                                        &nbsp;
                                        <a href="https://www.linkedin.com/company/cesa-edu/" className='toLink-clr-secundario font-bold' data-attribute-index="0" data-entity-type="MINI_COMPANY">CESA</a>&nbsp;y&nbsp;
                                        <a href="https://www.linkedin.com/company/endeavor-colombia/" className='toLink-clr-secundario font-bold' data-attribute-index="2" data-entity-type="MINI_COMPANY">Endeavor Colombia</a>. <br />
                                        ¡Nos veremos de nuevo en 2024!
                                    </span>
                                </span>
                            </span> */}
                        </div>
                    </div>

                    <div className=''>
                        <div className="" id="tabContent">
                            <div className="tab-pane fade show active" id="reclips" role="tabpanel" aria-labelledby="reclips-tab">
                                <div className='reclips-video_groupVideos'>
                                    <OnlyVideoItem
                                        linkVideoView='/reclips/product?re=7283157820948614406'
                                        linkImg="https://images.pexels.com/photos/8368970/pexels-photo-8368970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                        anclado={false}
                                    >
                                        <DescriptionVideo linkVideoView='/reclips/product?re=7283157820948614406' listTag={['cocina', 'desinfectante']} />
                                    </OnlyVideoItem>
                                    <OnlyVideoItem
                                        linkVideoView='/reclips/product?re=7283157820948614406'
                                        linkImg="https://images.pexels.com/photos/8368970/pexels-photo-8368970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                        anclado={false}
                                    >
                                        <DescriptionVideo linkVideoView='/reclips/product?re=7283157820948614406' listTag={['cocina', 'desinfectante']} />
                                    </OnlyVideoItem>
                                    <OnlyVideoItem
                                        linkVideoView='/reclips/product?re=7283157820948614406'
                                        linkImg="https://images.pexels.com/photos/8368970/pexels-photo-8368970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                        anclado={false}
                                    >
                                        <DescriptionVideo linkVideoView='/reclips/product?re=7283157820948614406' listTag={['cocina', 'desinfectante']} />
                                    </OnlyVideoItem>
                                    <OnlyVideoItem
                                        linkVideoView='/reclips/product?re=7283157820948614406'
                                        linkImg="https://images.pexels.com/photos/8368970/pexels-photo-8368970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                        anclado={false}
                                    >
                                        <DescriptionVideo linkVideoView='/reclips/product?re=7283157820948614406' listTag={['cocina', 'desinfectante']} />
                                    </OnlyVideoItem>
                                    <OnlyVideoItem
                                        linkVideoView='/reclips/product?re=7283157820948614406'
                                        linkImg="https://images.pexels.com/photos/8368970/pexels-photo-8368970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                        anclado={false}
                                    >
                                        <DescriptionVideo linkVideoView='/reclips/product?re=7283157820948614406' listTag={['cocina', 'desinfectante']} />
                                    </OnlyVideoItem>
                                    <OnlyVideoItem
                                        linkVideoView='/reclips/product?re=7283157820948614406'
                                        linkImg="https://images.pexels.com/photos/8368970/pexels-photo-8368970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                        anclado={false}
                                    >
                                        <DescriptionVideo linkVideoView='/reclips/product?re=7283157820948614406' listTag={['cocina', 'desinfectante']} />
                                    </OnlyVideoItem>
                                    <OnlyVideoItem
                                        linkVideoView='/reclips/product?re=7283157820948614406'
                                        linkImg="https://images.pexels.com/photos/8368970/pexels-photo-8368970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                        anclado={false}
                                    >
                                        <DescriptionVideo linkVideoView='/reclips/product?re=7283157820948614406' listTag={['cocina', 'desinfectante']} />
                                    </OnlyVideoItem>
                                    <OnlyVideoItem
                                        linkVideoView='/reclips/product?re=7283157820948614406'
                                        linkImg="https://images.pexels.com/photos/8368970/pexels-photo-8368970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                        anclado={false}
                                    >
                                        <DescriptionVideo linkVideoView='/reclips/product?re=7283157820948614406' listTag={['cocina', 'desinfectante']} />
                                    </OnlyVideoItem>
                                </div>
                            </div>


                        </div>
                    </div>


                </div>
                <div className='reclips-listVideos'>
                    <div className='reclips-listVideos_content'>
                        <div className='social-comment-cont--input'>
                            <div className="social-comment__comp-input">
                                <label htmlFor="inputPassword" className="social-comment__label col-form-label">
                                    <span className="social-comment__label-img">
                                        <div>
                                            <img src="https://github.com/mdo.png" loading="lazy" alt="Foto de Jorge Balderas" id="ember137" className="EntityPhoto-circle-0 evi-image lazy-image ember-view" />
                                        </div>
                                    </span>
                                </label>
                                <div className="social-comment--input">
                                    <textarea name="" className="form-control" id="inputPassword" cols={20} rows={1} placeholder='Escribe tu comentario'></textarea>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className=''>
                            <Comments moreComments={false}>
                                <Comments>
                                    <Comments> </Comments>
                                </Comments>
                                <Comments> </Comments>
                            </Comments>
                            <Comments moreComments={true}>
                                <Comments>
                                    <Comments> </Comments>
                                </Comments>
                                <Comments> </Comments>
                                <Comments> </Comments>
                                <Comments> </Comments>
                                <Comments> </Comments>
                                <Comments> </Comments>
                                <Comments> </Comments>
                            </Comments>
                        </div>
                    </div>
                </div>
            </div>
        </>
     
    );
};

export {Reclips};