import React from 'react';
import './ProductVariations.scss'

const ProductVariations = () => {
    return (
        <div className="var-variations">
            <div className="var-variations__picker">
                <div className="var-variations__title">
                    <p className="var-variations__label var-color--BLACK">Memoria interna:
                        <span className="var-variations__selected-label var-color--BLACK">
                            <span id="picker-label-INTERNAL_MEMORY" className="var-variations__selected-text">
                                <span className=" var-color--BLACK">64 GB</span>
                            </span>
                        </span>
                    </p>
                </div>
                <div className="var-variations__picker-default-container">
                    <a aria-current="true" href="/" className="var-thumbnail var-variations--thumbnail var-thumbnail--SELECTED" title="64 GB">
                        <div className="var-thumbnail__container">
                            <p className="var-thumbnail__label">64 GB</p>
                        </div>
                    </a>
                    <a href="/" className="var-thumbnail var-variations--thumbnail var-thumbnail--NONE" title="128 GB">
                        <div className="var-thumbnail__container">
                            <p className="var-thumbnail__label">128 GB</p>
                        </div>
                    </a>
                </div>
            </div>
            <div className="var-variations__picker">
                <div className="var-variations__title">
                    <p className="var-variations__label var-color--BLACK">Memoria RAM:<span className="var-variations__selected-label var-color--BLACK"><span id="picker-label-RAM" className="var-variations__selected-text" ><span className=" var-color--BLACK">4 GB</span></span></span></p>
                </div>
                <div className="var-variations__picker-default-container">
                    <a href="/" className="var-thumbnail var-variations--thumbnail var-thumbnail--NONE" title="3 GB">
                        <div className="var-thumbnail__container">
                            <p className="var-thumbnail__label">3 GB</p>
                        </div>
                    </a>
                    <a aria-current="true" href="/" className="var-thumbnail var-variations--thumbnail var-thumbnail--SELECTED" title="4 GB">
                        <div className="var-thumbnail__container">
                            <p className="var-thumbnail__label">4 GB</p>
                        </div>
                    </a>
                </div>
            </div>
            <div className="var-variations__picker">
                <div className="var-variations__title">
                    <p className="var-variations__label var-color--BLACK">Color:<span className="var-variations__selected-label var-color--BLACK"><span id="picker-label-COLOR" className="var-variations__selected-text"><span className=" var-color--BLACK">Gris grafito</span></span></span></p>
                </div>
                <div className="var-variations__picker-default-container">
                    <a href="/" className="var-thumbnail var-variations--thumbnail var-thumbnail--DISABLED">
                        <div>
                            <div className="andes-tooltip__trigger">
                                <div className="var-thumbnail__picture var-thumbnail__picture--LARGE" aria-describedby="informative-tippy-20"><img decoding="async" src="https://http2.mlstatic.com/D_Q_NP_712361-MLA50342739146_062022-R.webp" className="var-image" alt="Azul océano" /></div>
                            </div>
                            <span className="andes-visually-hidden" id="informative-tippy-20" aria-hidden="true"> Ver opciones disponibles</span>
                        </div>
                    </a>
                    <a aria-current="true" href="/" className="var-thumbnail var-variations--thumbnail var-thumbnail--SELECTED" title="Gris grafito">
                        <div className="var-thumbnail__picture var-thumbnail__picture--LARGE"><img decoding="async" src="https://http2.mlstatic.com/D_Q_NP_600484-MLA50342682195_062022-R.webp" className="var-image" alt="Gris grafito" /></div>
                    </a>
                    <a href="/" className="var-thumbnail var-variations--thumbnail var-thumbnail--DISABLED">
                        <div>
                            <div className="andes-tooltip__trigger">
                                <div className="var-thumbnail__picture var-thumbnail__picture--LARGE" aria-describedby="informative-tippy-21"><img decoding="async" src="https://http2.mlstatic.com/D_Q_NP_774292-MLA50342622467_062022-R.webp" className="var-image" alt="Verde menta" /></div>
                            </div>
                            <span className="andes-visually-hidden" id="informative-tippy-21" aria-hidden="true"> Ver opciones disponibles</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
};

export { ProductVariations };