import React from 'react';
import './PerfilMenu.scss'
import { Tolink } from '../../ToLink/Tolink';
import { BoxMenu } from '../BoxMenu';

const PerfilMenu = () => {
    return (
        <div className="dropdown text-end">
            <a href="/" className="d-block link-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                <span className='header-name'>Jorge</span>
                <img src="https://github.com/mdo.png" alt="mdo" width="32" height="32" className="rounded-circle" />
            </a>
            <ul className="container-menu-perfil dropdown-menu text-small" aria-labelledby="dropdownUser1">
                <li>
                    <div className=''>
                        <span className='text-informativo'>Ir a mi perfil</span>
                        <Tolink to='/usuario/yorch256' classlink='d-flex link-dark text-decoration-none mb-2'>
                            <img src="https://github.com/mdo.png" alt="mdo" width="32" height="32" className="rounded-circle" />
                            <div className='dropdown-name-area'>
                                <span className='dropdown-name'>Jorge Balderas</span>
                                <samp className='text-informativo'>500 puntos</samp>
                            </div>
                        </Tolink>
                        <Tolink to='/suscripcion' classlink='btn button_primario active'>
                            Suscribete a Clisvi plus
                        </Tolink>
                    </div>
                </li>
                <BoxMenu nameColeccion='Mis pedidos'>
                    <li><Tolink to='/pedidos' classlink='itemLinkMenu hover-principal dropdown-link'>Pedidos</Tolink></li>
                    <li><Tolink to='/preguntas' classlink='itemLinkMenu hover-principal dropdown-link'>Preguntas</Tolink></li>
                    <li><Tolink to='/opiniones' classlink='itemLinkMenu hover-principal dropdown-link'>Daja tu experiencia (1)</Tolink></li>
                </BoxMenu>
                <BoxMenu nameColeccion=''>
                    <li><Tolink to='/vender' classlink='itemLinkMenu dropdown-link toLink-clr-principal text-bold'>Quiero vender</Tolink> </li>
                    <li><Tolink to='/afiliacion' classlink='itemLinkMenu dropdown-link hover-principal text-bold'>Quiero afiliarme</Tolink> </li>
                    <li><Tolink to='/publicidad' classlink='itemLinkMenu hover-principal dropdown-link'>Publicidad</Tolink></li>
                    <li><Tolink to='/favoritos' classlink='itemLinkMenu hover-principal dropdown-link'>Mis favoritos</Tolink></li>
                </BoxMenu>
                <BoxMenu nameColeccion='Mis páginas'>
                    <li><Tolink to='/store/3hermanos' classlink='itemLinkMenu hover-principal dropdown-link'>3 Hermanos (ventas)</Tolink></li>
                    <li><Tolink to='/servicio/carpinteria' classlink='itemLinkMenu hover-principal dropdown-link'>Carpintaería Pepe (servicio)</Tolink></li>
                    <li><Tolink to='/registro-pagina' classlink='itemLinkMenu hover-principal dropdown-link'><em>Crear nueva página</em></Tolink></li>
                </BoxMenu>
                <BoxMenu nameColeccion='Configuración'>
                    <li><Tolink to='/seguridad' classlink='itemLinkMenu hover-principal dropdown-link'>Seguridad</Tolink> </li>
                    <li><Tolink to='/privacidad' classlink='itemLinkMenu hover-principal dropdown-link'>Privacidad</Tolink></li>
                </BoxMenu>
                <BoxMenu nameColeccion=''>
                    <li> <Tolink to='/signin' classlink='itemLinkMenu hover-principal dropdown-link'>Cerrar sesión</Tolink></li>
                </BoxMenu>
            </ul>
        </div>
    );
};

export { PerfilMenu };