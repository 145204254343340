import React from 'react';

import "./Header.css";
import { Tolink } from '../../Components/ToLink/Tolink';
import { Icon } from '../../Components/Icon/Icon';
import { Button } from '../../Components/Button/Button';
import { Logo } from '../../Components/Header/Logo';
import { Direccion } from '../../Components/Header/Direccion';
import { Notifications } from '../../Components/Header/Notification/Notification';
import { PerfilMenu } from '../../Components/Header/PerfilMenu/PerfilMenu';
import { Search } from '../../Components/Header/Search';

import { NavMain } from '../../Components/Header/NavMain/NavMain';
const Header = () => {

    const [conLogin, setConLogin] = React.useState(true);
    const pruebaClick = () => {
        console.log('realizado')
    }

    return (
        <header className='nav-header'>
            <div className="nav-bounds d-flex flex-wrap align-items-center justify-content-between">
                <Logo />
                <div className='nav-header-center'>
                    <Direccion />
                    <Search />
                    <div className='nav-header-button'>
                        <Tolink to='/publicar'>
                            <Button onClick={pruebaClick}>Publicar</Button>
                        </Tolink>
                    </div>
                </div>
                <div className='nav-header-right'>
                    <Tolink to='/shop'><Icon classIcon='shop' quantity={0} /></Tolink>
                    {
                        conLogin ?
                        <>
                            <Tolink to='/carrito'><Icon classIcon='cart-fill' quantity={0} /></Tolink>
                            <Notifications />
                            <PerfilMenu />
                        </>
                        :
                        <>
                            <Tolink to='/register'>
                                <strong>
                                Crear cuenta
                                </strong>
                            </Tolink>
                            &nbsp;
                                ó
                            &nbsp; 
                            <Tolink to='/signin'>
                                Iniciar sesion
                            </Tolink>
                        </>
                    }
                </div>
            </div>
            <NavMain />
        </header>
    );
};

export { Header };