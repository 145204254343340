import React from 'react';
import './StarProgressBar.scss'

interface Props {
    name: string
    porcentaje: number
    numberStar: number
}

const StarProgressBar: React.FC<Props> = ({ name, porcentaje, numberStar }) => {
    return (
        <div role='group' className='filter-star-item'>
            <div className='filter-description filter-text'>
                <span className=''>{name}</span>
            </div>
            <div className='filter-progress'>
                <div className="progress">
                    <div className="progress-bar bg-success" style={{ width: `${porcentaje}%` }} role="progressbar" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                </div>
            </div>
            <div className='filter-porcentaje-item filter-text'>
                <span>{numberStar}</span>
            </div>
        </div>
    );
};

export { StarProgressBar };