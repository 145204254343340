import React from 'react';
import './ProfileStore.scss'
import { Tolink } from '../../Components/ToLink/Tolink';
import { Button } from '../../Components/Button/Button';
import { Stars } from '../../Components/Stars/Stars';

import Oro from '../../Assets/Image/Prestigio/bronce.png'
import Plata from '../../Assets/Image/Prestigio/plata.png'
import Bronce from '../../Assets/Image/Prestigio/bronce.png'

interface Props {
    imgProfile: string
    name: string
    user: string
    showStarts?: boolean
    score?: number //si showStarts es falso este valor es opcional
    ratings?: number //si showStarts es falso este valor es opcional
    desciption: string
    noFollowing: string
    noFollowers: string
    ImgPrestigio: string
}

const ProfileStore: React.FC<Props> = ({ imgProfile, name, user, showStarts = true, score = 0, ratings = 0, desciption, noFollowing, noFollowers, ImgPrestigio }) => {
    return (
        <section className='profile-secction-header'>
            <div className='profile-header'>
                <div className='profile-header--content-banner'>
                    <div className='profile-header--banner'>
                        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img src="https://png.pngtree.com/background/20221014/original/pngtree-youtube-banner-social-media-purple-ornate-style-background-picture-image_1920459.jpg" className="d-block w-100" alt="..." />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>First slide label</h5>
                                        <p>Some representative placeholder content for the first slide.</p>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src="https://png.pngtree.com/background/20221014/original/pngtree-youtube-banner-social-media-blue-abstract-style-background-picture-image_1920454.jpg" className="d-block w-100" alt="..." />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>Second slide label</h5>
                                        <p>Some representative placeholder content for the second slide.</p>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src="https://png.pngtree.com/background/20221014/original/pngtree-youtube-banner-social-media-light-green-abstract-style-background-picture-image_1920462.jpg" className="d-block w-100" alt="..." />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>Third slide label</h5>
                                        <p>Some representative placeholder content for the third slide.</p>
                                    </div>
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='profile-main w-100'>
                <div className='profile__head w-100'>
                    <div className='profile__head-wrapper'>
                        <div className='profile__head-wrapper-picture'>
                            <div className='profile__head--picture'>
                                <img src={imgProfile} alt="" sizes="" />
                            </div>
                        </div>
                        <div className='profile__head-wrapper-info'>
                            <div>
                                <div className='profile__head-info--name'>
                                    <span className='profile-main--info-name'>{name}</span>
                                    <span className='profile-main--info-verify' data-bs-toggle="tooltip" data-bs-placement="top" title="Cuenta verificada">
                                        <i className="bi bi-patch-check-fill"></i>
                                    </span>
                                </div>

                                <div className='profile__head-info--user'>
                                    <span className='profile-main--info-user'>@{user}</span>
                                </div>
                            </div>
                            {showStarts &&
                                <div className='profile__head-info--score'>
                                    {/* <button className="nav-link" id="score-tab" data-bs-toggle="tab" data-bs-target="#score" type="button" role="tab" aria-controls="score" aria-selected="true">
                                        <Stars score={score} showTotal={true} ratings={ratings} />
                                    </button> */}
                                    <Stars score={score} showTotal={true} ratings={ratings} />
                                    {/* <Tolink to='/store/score/34535-343fafsfasdfc' classlink='toLink-black_hover'>
                                    </Tolink> */}
                                </div>
                            }
                        </div>

                        <div className='profile__head-brand-description'>
                            <div className='profile__head--description'>
                                <p>
                                    {desciption}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='profile__head-wrapper-right'>
                        <div className='profile__head-wrapper-followers'>
                            <div className='brand-buttons--follower'>
                                <Button classBtn='button_secundario '>
                                    Seguir
                                </Button>
                            </div>
                            <div className='brand-buttons--message'>
                                <Button classBtn='button_secundario active'>
                                    Mensaje
                                </Button>
                            </div>
                            <div className='brand-buttons--config'>
                                <div className="dropdown">
                                    {/* <Button classBtn='button_grey'>
                                            <i className="bi bi-three-dots-vertical"></i>
                                        </Button> */}
                                    <button className="btn btn-secondary button_opcions" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical"></i>
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item" href="#">Denunciar</a></li>
                                        <li><a className="dropdown-item" href="#">Bloquear</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='profile-wrapper-info--followers'>
                            <p className='profile--number-followers'>
                                <Tolink to='' classlink='toLink'>
                                    <span className='info-followers--number'>{noFollowing} </span>
                                    <samp className='info-followers--text'>Siguiendo</samp>
                                </Tolink>
                                &nbsp;&nbsp;
                                <Tolink to='' classlink='toLink'>
                                    <samp className='info-followers--number'>{noFollowers} </samp>
                                    <samp className='info-followers--text'>Seguidores</samp>
                                </Tolink>
                            </p>
                        </div>
                        <div className='profile__head-wrapper-prestige'>
                            <div className='profile__head-prestige'>
                                <div className='prestige-img'>
                                    <img src={ImgPrestigio} className="d-block" alt="..." width='45px' height='40px' />
                                    {/* <img src={Plata} className="d-block" alt="..." width='30px' height='40px' />
                                    <img src={Bronce} className="d-block" alt="..." width='30px' height='40px' /> */}
                                </div>
                                <span className='text-prestige'>
                                    Prestigio: <strong>Golden</strong>
                                </span>
                                {/* <p>(Golden - Platinu - Bronze - No cuenta con prestigio)</p> */}
                            </div>
                            <p className='mt-2 mb-1'>
                                10.5 K ventas en los ultimos 90 días
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export { ProfileStore };