import React from 'react';
import "./NavMain.scss"

import { NavItem } from './NavItem';

const NavMain = () => {
    return (
        <div className='cont-nav-menu-list'>
            <ul className='nav-menu-list'>
                <NavItem url='/shop'>Clisvi shop</NavItem>
                <NavItem url='/vender'>Quiero vender</NavItem>
                <NavItem url='/afiliacion'>Quiero afiliarme</NavItem>
                <NavItem url='/tendencias'>Tendencia</NavItem>
                <NavItem url='/test'>Lo más vendido</NavItem>
                <NavItem url='/test'>Comprar de nuevo</NavItem>
                <NavItem url='/favoritos'>Favoritos</NavItem>
                <NavItem url='/historial'>Historial de busqueda</NavItem>
                <NavItem url='/cupones'>Cupones</NavItem>
                <NavItem url='/Gestionar'>Gestionar páginas</NavItem>
                <NavItem url='/suscripcion'>Clisvi plus</NavItem>
            </ul>
        </div>
    );
};

export { NavMain };