import React from 'react';
import './ListClips.scss'

import { OnlyVideoItem } from '../../Components/OnlyVideoItem/OnlyVideoItem';
import { DescriptionVideo } from '../../Components/VideoInfoItem/DescriptionVideo';

const ListClips = () => {
    return (
        <div className='ListClips'>
            <OnlyVideoItem
                linkVideoView='/watch?clip=7283157820948614406'
                linkImg="https://images.pexels.com/photos/8368970/pexels-photo-8368970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                anclado={true}
            >
                <DescriptionVideo linkVideoView='/watch?clip=7283157820948614406' listTag={['cocina', 'desinfectante']} />
            </OnlyVideoItem>
            <OnlyVideoItem
                linkVideoView='/watch?clip=7283157820948614406'
                linkImg="https://images.pexels.com/photos/7507964/pexels-photo-7507964.jpeg?auto=compress&cs=tinysrgb&w=600"
                anclado={true}
            >
                <DescriptionVideo linkVideoView='/watch?clip=7283157820948614406' listTag={['cocina', 'desinfectante']} />
            </OnlyVideoItem>
            <OnlyVideoItem
                linkVideoView='/watch?clip=7283157820948614406'
                linkImg="https://images.pexels.com/photos/14447086/pexels-photo-14447086.jpeg?auto=compress&cs=tinysrgb&w=600"
                anclado={true}
            >
                <DescriptionVideo linkVideoView='/watch?clip=7283157820948614406' listTag={['cocina', 'desinfectante']} />
            </OnlyVideoItem>
            <OnlyVideoItem
                linkVideoView='/watch?clip=7283157820948614406'
                linkImg="https://images.pexels.com/photos/8368970/pexels-photo-8368970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            >
                <DescriptionVideo linkVideoView='/watch?clip=7283157820948614406' listTag={['cocina', 'desinfectante']} />
            </OnlyVideoItem>
            <OnlyVideoItem
                linkVideoView='/watch?clip=7283157820948614406'
                linkImg="https://images.pexels.com/photos/8373959/pexels-photo-8373959.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            >
                <DescriptionVideo linkVideoView='/watch?clip=7283157820948614406' listTag={['cocina', 'desinfectante']} />
            </OnlyVideoItem>
            <OnlyVideoItem
                linkVideoView='/watch?clip=7283157820948614406'
                linkImg="https://images.pexels.com/photos/8368970/pexels-photo-8368970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            >
                <DescriptionVideo linkVideoView='/watch?clip=7283157820948614406' listTag={['cocina', 'desinfectante']} />
            </OnlyVideoItem>
            <OnlyVideoItem
                linkVideoView='/watch?clip=7283157820948614406'
                linkImg="https://images.pexels.com/photos/8368970/pexels-photo-8368970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            >
                <DescriptionVideo linkVideoView='/watch?clip=7283157820948614406' listTag={['cocina', 'desinfectante']} />
            </OnlyVideoItem>
            <OnlyVideoItem
                linkVideoView='/watch?clip=7283157820948614406'
                linkImg="https://images.pexels.com/photos/8368970/pexels-photo-8368970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            >
                <DescriptionVideo linkVideoView='/watch?clip=7283157820948614406' listTag={['cocina', 'desinfectante']} />
            </OnlyVideoItem>
            <OnlyVideoItem
                linkVideoView='/watch?clip=7283157820948614406'
                linkImg="https://images.pexels.com/photos/14447086/pexels-photo-14447086.jpeg?auto=compress&cs=tinysrgb&w=600"
            >
                <DescriptionVideo linkVideoView='/watch?clip=7283157820948614406' listTag={['cocina', 'desinfectante']} />
            </OnlyVideoItem>
            <OnlyVideoItem
                linkVideoView='/watch?clip=7283157820948614406'
                linkImg="https://images.pexels.com/photos/8368970/pexels-photo-8368970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            >
                <DescriptionVideo linkVideoView='/watch?clip=7283157820948614406' listTag={['cocina', 'desinfectante']} />
            </OnlyVideoItem>
            <OnlyVideoItem
                linkVideoView='/watch?clip=7283157820948614406'
                linkImg="https://images.pexels.com/photos/8368970/pexels-photo-8368970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            >
                <DescriptionVideo linkVideoView='/watch?clip=7283157820948614406' listTag={['cocina', 'desinfectante']} />
            </OnlyVideoItem>
            <OnlyVideoItem
                linkVideoView='/watch?clip=7283157820948614406'
                linkImg="https://images.pexels.com/photos/8373959/pexels-photo-8373959.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            >
                <DescriptionVideo linkVideoView='/watch?clip=7283157820948614406' listTag={['cocina', 'desinfectante']} />
            </OnlyVideoItem>
        </div>
    );
};

export { ListClips };