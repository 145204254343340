import React from 'react';
import './SocialReactionsIndex.scss'

import { Comments } from '../../Components/Comments/Comments';
import { Reactions } from '../../Components/Reactions/Reactions';
import { Tolink } from '../../Components/ToLink/Tolink';
import { OnlyVideo } from '../../Components/OnlyVideo/OnlyVideo';

const SocialReactionsIndex = () => {
    return (
        <>
            <div className='video-social--display'>
                <OnlyVideo />
            </div>
            <div className='social-details--activity'>
                <div className='social-details--counts social-details-reactions'>
                    <ul className='social-details-social-counts'>
                        <li className='social-details--counts__item social-details--counts__reactions'>
                            <button aria-label="5792 reacciones a la publicación de INSPIRAS" className='social-details--counts__count-value'>
                                <img src="https://static.licdn.com/aero-v1/sc/h/8ekq8gho1ruaf8i7f86vd1ftt" alt="like" className='social-detail-social-counts__count-icon' />
                                <img src="https://static.licdn.com/aero-v1/sc/h/cpho5fghnpme8epox8rdcds22" alt="like" className='social-detail-social-counts__count-icon' />
                                <img src="	https://static.licdn.com/aero-v1/sc/h/3wqhxqtk2l554o70ur3kessf1" alt="like" className='social-detail-social-counts__count-icon' />
                                <span className='social-details-social-counts__reactions-count'>5792</span>
                            </button>
                        </li>
                        <li className='social-details--counts__item social-details--counts__comments'>
                            <Tolink to='/watch?clip=7283157820948614406&v=shop'>
                                <button aria-label="33 comentarios en la publicación de INSPIRAS" className='social-details--counts__count-value'>
                                    <span className='social-details-social-counts__reactions-count'>33 comentarios</span>
                                </button>
                            </Tolink>
                        </li>
                        <li className='social-details--counts__item social-details--counts__shared'>
                            <button aria-label="Publicación de INSPIRAS 757 veces compartida" className='social-details--counts__count-value'>
                                <span className='social-details-social-counts__reactions-count'>757 veces compartido</span>
                            </button>
                        </li>
                    </ul>
                </div>



                <div className='social-details-base__social--actions'>
                    <div className='social-details--bar__container-reaccions'>

                        <span role='group' className="social-details-bar__action--reaccions social-reactions-accion-button dropup">
                            <Reactions classButton='social-details-reaccions__action--button social-bar-button'>
                                <i className="bi bi-hand-thumbs-up"></i>
                            </Reactions>
                        </span>
                        <span role='group' className="social-details-bar__action--reaccions reactions-react-button">
                            <Tolink to='/watch?clip=7283157820948614406&v=shop'>
                                <button type="button" className="social-details-reaccions__action--button social-bar-button "><i className="bi bi-chat-right-text"></i> Comentar</button>
                            </Tolink>
                        </span>
                        <span role='group' className="social-details-bar__action--reaccions reactions-react-button">
                            <button type="button" className="social-details-reaccions__action--button social-bar-button">
                                <i className="bi bi-repeat"></i>Compartir
                            </button>
                        </span>
                        <span role='group' className="social-details-bar__action--reaccions dropstart ">
                            <button aria-label="Abrir menú para cambiar de identidad al interactuar con esta publicación" id="ember136"
                                className="social-details-reaccions__action--button social-bar-button dropdown-toggle"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="social-details-reaccions__info-picture">
                                    <div>
                                        <img src="https://github.com/mdo.png" loading="lazy" alt="Foto de Jorge Balderas" id="ember137" className="EntityPhoto-circle-0 evi-image lazy-image ember-view" />
                                    </div>
                                </span>
                            </button>
                            <div className="dropdown-menu social-drop--change-perfil" aria-labelledby="ember136">
                                <samp className='social-drop--change-text-title'>
                                    <div className=''>
                                        <h3>Elige como interactuar</h3>
                                        <span>
                                            Puedes interactuar como tu perfil o una página que administres.
                                            <Tolink to='' classlink='toLink-clr-secundario'>
                                                Más información.
                                            </Tolink>
                                        </span>
                                    </div>
                                </samp>
                                <hr />
                                <samp className="social-drop--change-perfil-user dropdown-item">
                                    <div className='social-card-column__video--infoUser'>
                                        <div className='social-video-infoUser--photo'>
                                            <img src="https://github.com/mdo.png" alt="" sizes="" />
                                        </div>
                                        <div className='social-video-infoUser--data'>
                                            <span className='social-video-infoUser--name'>Mi nombre</span>
                                            <samp className='social-video-infoUser--ckeck'>
                                                <i className="bi bi-check-circle-fill"></i>
                                            </samp>
                                        </div>
                                    </div>
                                </samp>
                                <samp className="social-drop--change-perfil-user dropdown-item">
                                    <div className='social-card-column__video--infoUser'>
                                        <div className='social-video-infoUser--photo'>
                                            <img src="https://avatars.githubusercontent.com/u/63648?v=4" alt="" sizes="" />
                                        </div>
                                        <div className='social-video-infoUser--data'>
                                            <span className='social-video-infoUser--name'>Nombre página</span>
                                            <samp className='social-video-infoUser--ckeck'>
                                                <i className="bi bi-check-circle"></i>
                                            </samp>
                                        </div>
                                    </div>
                                </samp>
                                <samp className="social-drop--change-perfil-user dropdown-item">
                                    <div className='social-card-column__video--infoUser'>
                                        <div className='social-video-infoUser--photo'>
                                            <img src="https://avatars.githubusercontent.com/u/197597?v=4" alt="" sizes="" />
                                        </div>
                                        <div className='social-video-infoUser--data'>
                                            <span className='social-video-infoUser--name'>Nombre página 2</span>
                                            <samp className='social-video-infoUser--ckeck'>
                                                <i className="bi bi-check-circle"></i>
                                            </samp>
                                        </div>
                                    </div>
                                </samp>
                                <samp className="social-drop--change-perfil-user dropdown-item">
                                    <div className='social-card-column__video--infoUser'>
                                        <div className='social-video-infoUser--photo'>
                                            <img src="https://avatars.githubusercontent.com/u/339286?v=4" alt="" sizes="" />
                                        </div>
                                        <div className='social-video-infoUser--data'>
                                            <span className='social-video-infoUser--name'>Anonimo</span>
                                            <samp className='social-video-infoUser--ckeck'>
                                                <i className="bi bi-check-circle"></i>
                                            </samp>
                                        </div>
                                    </div>
                                </samp>
                            </div>
                        </span>
                    </div>
                </div>


                {/* <div className='social-comment'>
                    <div className='social-comment-cont--input'>
                        <div className="social-comment__comp-input">
                            <label htmlFor="inputPassword" className="social-comment__label col-form-label">
                                <span className="social-comment__label-img">
                                    <div>
                                        <img src="https://github.com/mdo.png" loading="lazy" alt="Foto de Jorge Balderas" id="ember137" className="EntityPhoto-circle-0 evi-image lazy-image ember-view" />
                                    </div>
                                </span>
                            </label>
                            <div className="social-comment--input">
                                <textarea name="" className="form-control" id="inputPassword" cols={20} rows={1}></textarea>
                            </div>
                        </div>
                    </div>


                    <div className='social-comment-base__coments'>
                        <Comments moreComments={true}>
                            <Comments>
                            <Comments> </Comments>
                            </Comments>
                            <Comments> </Comments>
                        </Comments>
                        <Comments> </Comments>
                        <Comments> </Comments>
                        <div className='social-comment__content__list--comment'>
                            <div className='social-comment-content--totals-number'>
                                <button aria-label="Ver 253 comentarios más" className='social-details--counts__count-value'>
                                    <span className='social-comment-content--totals-number'>Ver más comentarios (2563)</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export { SocialReactionsIndex };