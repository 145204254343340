import React, { FC } from 'react';
import './Video.css'
import ReactPlayer from 'react-player/lazy'


interface Props {
    url: string
    muted?: boolean
    playing?: boolean
}


const Video: FC<Props> = ({ url, playing, muted = true }) => {
    return (
        <>
            <div className='position-relative Video'>
                <ReactPlayer
                    url={url}
                    width='100%'
                    height="100%"
                    loop={true}
                    controls={true}
                    playing={playing}
                    muted={muted}
                    config={{
                        youtube: {
                            playerVars: { showinfo: 1 },
                            embedOptions: {},
                        },
                        file: {

                        }
                    }}
                />
                <div className='position-absolute controls'></div>
            </div>
        </>
    );

};

export { Video };