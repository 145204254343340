import React from 'react';
import { Tolink } from '../ToLink/Tolink';

const Direccion = () => {
    return (
        <div className='nav-header-address'>
            <Tolink to='/direcciones' classlink='toLink-clr-white'>
                <div>Mi ubicación</div>
                <div><strong>Tulancingo... CP 74236</strong></div>
            </Tolink>
        </div>
    );
};

export {Direccion};