import React from "react";

//#region Recursos
import "./ShopHome.scss";
import { Video } from "../../Components/Video/Video";
import imgM from "../../Assets/Image/Home/favoritos-144.svg";
//#endregion Recursos

import { Tolink } from "../../Components/ToLink/Tolink";
import { SeccionNavInfo } from "../../Containers/SeccionNavInfo/SeccionNavInfo";
import { SeccionNavInfoItem } from "../../Components/SeccionNavInfoItem/SeccionNavInfoItem";
import { SectionCarrucel } from "../../Containers/SectionCarrucel/SectionCarrucel";
import { VideoProductItem } from "../../Components/VideoProductItem/VideoInfoItem";
import { StoreCarouselItem } from "../../Components/StoreInfoItem/StoreInfoItem";
import { VideoInfoItem } from "../../Components/VideoInfoItem/VideoInfoItem"
import { InfoUser } from "../../Components/InfoUser/InfoUser";
import { OnlyVideoItem } from "../../Components/OnlyVideoItem/OnlyVideoItem";
import { DescriptionVideo } from "../../Components/VideoInfoItem/DescriptionVideo";
import { DetailProduct } from "../../Components/VideoProductItem/DetailProduct";

const ShopHome = () => {
    document.title = "Clisvi Shop";
    return (
        <main className="ShopHome">
            <section className="ShopHome-Video">
                <Video
                    url="https://www.youtube.com/watch?v=Rxxy6vZNJnE&ab_channel=Coca-Cola"
                    playing={true}
                />
            </section>

            <div className="seccions">
                <SeccionNavInfo className="navInfo">
                    <Tolink to="/" classlink="toLink-clr-black">
                        <SeccionNavInfoItem image={imgM} title="Ver clips" />
                    </Tolink>
                    <Tolink to="/categorias" classlink="toLink-clr-black">
                        <SeccionNavInfoItem image={imgM} title="Categorías" />
                    </Tolink>
                    <Tolink to="/afiliacion" classlink="toLink-clr-black">
                        <SeccionNavInfoItem image={imgM} title="Afiliate y gana" />
                    </Tolink>
                    <Tolink to="/vender" classlink="toLink-clr-black">
                        <SeccionNavInfoItem image={imgM} title="Quiero vender" />
                    </Tolink>
                    <Tolink to="/favoritos" classlink="toLink-clr-black">
                        <SeccionNavInfoItem image={imgM} title="Favoritos" />
                    </Tolink>
                    <Tolink to="/garantia" classlink="toLink-clr-black">
                        <SeccionNavInfoItem image={imgM} title="Garantia de compra" />
                    </Tolink>
                    <Tolink to="/metodo-de-pago" classlink="toLink-clr-black">
                        <SeccionNavInfoItem image={imgM} title="Metodos de pago" />
                    </Tolink>
                </SeccionNavInfo>

                <SectionCarrucel title="Clips" textlink="Ver Clips" url="/watch?clip=7283157820948614406&v=shop" >
                    <VideoInfoItem>
                        <InfoUser
                            imgProfile='https://github.com/mdo.png'
                            name='Jorge Prueba'
                            link='/usuario/jorge1235'
                            showVerify={false}
                            user=""
                        >
                            <span>Hace 1h</span>
                        </InfoUser>
                        <div className="clip-column__video">
                            <OnlyVideoItem
                                linkImg='https://images.pexels.com/photos/3791466/pexels-photo-3791466.jpeg?auto=compress&cs=tinysrgb&w=600'
                                linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                isVenta={false}
                                videoViews="25 K"
                            >
                                <DescriptionVideo
                                    linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                    description='¿Funcionan? o son otro engaño mas? prueba texto jhsdbsbd sudghf iogfso fsugdf lo gsdolodsgfol sgdfl'
                                    listTag={['cocina', 'desinfectante']} />
                            </OnlyVideoItem>
                        </div>
                    </VideoInfoItem>
                    <VideoInfoItem>
                        <InfoUser
                            imgProfile='https://github.com/mdo.png'
                            name='Jorge Prueba'
                            link='/usuario/jorge1235'
                            showVerify={false}
                            user=""
                        >
                            <span>Hace 1h</span>
                        </InfoUser>
                        <div className="clip-column__video">
                            <OnlyVideoItem
                                linkImg='https://images.pexels.com/photos/3791466/pexels-photo-3791466.jpeg?auto=compress&cs=tinysrgb&w=600'
                                linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                isVenta={false}
                                videoViews="25 K"
                            >
                                <DescriptionVideo
                                    linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                    description='¿Funcionan? o son otro engaño mas? prueba texto jhsdbsbd sudghf iogfso fsugdf lo gsdolodsgfol sgdfl'
                                    listTag={['cocina', 'desinfectante']} />
                            </OnlyVideoItem>
                        </div>
                    </VideoInfoItem>
                    <VideoInfoItem>
                        <InfoUser
                            imgProfile='https://github.com/mdo.png'
                            name='Jorge Prueba'
                            link='/usuario/jorge1235'
                            showVerify={false}
                            user=""
                        >
                            <span>Hace 1h</span>
                        </InfoUser>
                        <div className="clip-column__video">
                            <OnlyVideoItem
                                linkImg='https://images.pexels.com/photos/3791466/pexels-photo-3791466.jpeg?auto=compress&cs=tinysrgb&w=600'
                                linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                isVenta={false}
                                videoViews="25 K"
                            >
                                <DescriptionVideo
                                    linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                    description='¿Funcionan? o son otro engaño mas? prueba texto jhsdbsbd sudghf iogfso fsugdf lo gsdolodsgfol sgdfl'
                                    listTag={['cocina', 'desinfectante']} />
                            </OnlyVideoItem>
                        </div>
                    </VideoInfoItem>
                    <VideoInfoItem>
                        <InfoUser
                            imgProfile='https://github.com/mdo.png'
                            name='Jorge Prueba'
                            link='/usuario/jorge1235'
                            showVerify={false}
                            user=""
                        >
                            <span>Hace 1h</span>
                        </InfoUser>
                        <div className="clip-column__video">
                            <OnlyVideoItem
                                linkImg='https://images.pexels.com/photos/3791466/pexels-photo-3791466.jpeg?auto=compress&cs=tinysrgb&w=600'
                                linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                isVenta={false}
                                videoViews="25 K"
                            >
                                <DescriptionVideo
                                    linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                    description='¿Funcionan? o son otro engaño mas? prueba texto jhsdbsbd sudghf iogfso fsugdf lo gsdolodsgfol sgdfl'
                                    listTag={['cocina', 'desinfectante']} />
                            </OnlyVideoItem>
                        </div>
                    </VideoInfoItem>
                    <VideoInfoItem>
                        <InfoUser
                            imgProfile='https://github.com/mdo.png'
                            name='Jorge Prueba'
                            link='/usuario/jorge1235'
                            showVerify={false}
                            user=""
                        >
                            <span>Hace 1h</span>
                        </InfoUser>
                        <div className="clip-column__video">
                            <OnlyVideoItem
                                linkImg='https://images.pexels.com/photos/3791466/pexels-photo-3791466.jpeg?auto=compress&cs=tinysrgb&w=600'
                                linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                isVenta={false}
                                videoViews="25 K"
                            >
                                <DescriptionVideo
                                    linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                    description='¿Funcionan? o son otro engaño mas? prueba texto jhsdbsbd sudghf iogfso fsugdf lo gsdolodsgfol sgdfl'
                                    listTag={['cocina', 'desinfectante']} />
                            </OnlyVideoItem>
                        </div>
                    </VideoInfoItem>
                    <VideoInfoItem>
                        <InfoUser
                            imgProfile='https://github.com/mdo.png'
                            name='Jorge Prueba'
                            link='/usuario/jorge1235'
                            showVerify={false}
                            user=""
                        >
                            <span>Hace 1h</span>
                        </InfoUser>
                        <div className="clip-column__video">
                            <OnlyVideoItem
                                linkImg='https://images.pexels.com/photos/3791466/pexels-photo-3791466.jpeg?auto=compress&cs=tinysrgb&w=600'
                                linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                isVenta={false}
                                videoViews="25 K"
                            >
                                <DescriptionVideo
                                    linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                    description='¿Funcionan? o son otro engaño mas? prueba texto jhsdbsbd sudghf iogfso fsugdf lo gsdolodsgfol sgdfl'
                                    listTag={['cocina', 'desinfectante']} />
                            </OnlyVideoItem>
                        </div>
                    </VideoInfoItem>
                    <VideoInfoItem>
                        <InfoUser
                            imgProfile='https://avatars.githubusercontent.com/u/197597?v=4'
                            name='Tienda'
                            link='/store/tienda'
                            showVerify={true}
                            user=""
                        >
                            <span>Hace 12h</span>
                        </InfoUser>
                        <div className="clip-column__video">
                            <OnlyVideoItem
                                linkImg='https://images.pexels.com/photos/8534463/pexels-photo-8534463.jpeg?auto=compress&cs=tinysrgb&w=600'
                                linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                isVenta={false}
                                videoViews="25 K"
                            >
                                <DescriptionVideo
                                    linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                    description='¿Funcionan? o son otro engaño mas? prueba texto jhsdbsbd sudghf iogfso fsugdf lo gsdolodsgfol sgdfl'
                                    listTag={['cocina', 'desinfectante']} />
                            </OnlyVideoItem>
                        </div>
                    </VideoInfoItem>
                </SectionCarrucel>

                <SectionCarrucel title="Promocionados" textlink="Anunciate aquí" url="/anunciarme">
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7507964/pexels-photo-7507964.jpeg?auto=compress&cs=tinysrgb&w=600'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>                    
                </SectionCarrucel>

                <SectionCarrucel title="Productos más vendidos" textlink="Ver más" url="/masvendidos">
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7507964/pexels-photo-7507964.jpeg?auto=compress&cs=tinysrgb&w=600'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                </SectionCarrucel>

                <SectionCarrucel title="Tiendas recomendadas" textlink="Ver más tendas" url="/tiendas">
                    <StoreCarouselItem nameProfile="Samsung" userProfile="samsung" imagePortada="https://http2.mlstatic.com/storage/official-stores-images/samsung/background_home201806021214.jpg" imageProfile="https://http2.mlstatic.com/D_Q_NP_897846-MLA25771601945_072017-AE.webp" />
                    <StoreCarouselItem nameProfile="Bosch" userProfile="bosch" imagePortada="https://http2.mlstatic.com/storage/official-stores-images/bosch/background_home201904160142.jpg" imageProfile="https://http2.mlstatic.com/D_Q_NP_962690-MLA29917868829_042019-AE.webp" />
                    <StoreCarouselItem nameProfile="DC Shoes" userProfile="dcshoes" imagePortada="https://http2.mlstatic.com/storage/official-stores-images/dcshoes/background_home201809270706.jpg" imageProfile="https://http2.mlstatic.com/D_Q_NP_919932-MLA25849522923_082017-AE.webp" />
                    <StoreCarouselItem nameProfile="Nintendo" userProfile="nintendo" imagePortada="https://http2.mlstatic.com/storage/official-stores-images/nintendo/background_home201802280856.jpg" imageProfile="https://mla-s2-p.mlstatic.com/854419-MLA45451028132_042021-AE.webp" />
                    <StoreCarouselItem nameProfile="Stanley" userProfile="stanley" imagePortada="https://http2.mlstatic.com/storage/official-stores-images/stanley/background_home201802210503.jpg" imageProfile="https://http2.mlstatic.com/D_Q_NP_945081-MLA25716380610_062017-AE.webp" />
                    <StoreCarouselItem nameProfile="Andrea" userProfile="andrea" imagePortada="https://http2.mlstatic.com/storage/official-stores-images/andrea/background_home202003210430.jpg" imageProfile="https://http2.mlstatic.com/D_NQ_NP_722987-MLA69550145729_052023-AE.webp" />
                </SectionCarrucel>

                <SectionCarrucel title="Basado en tus ultimas busquedas" textlink="Ver más" url="/productos">
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7507964/pexels-photo-7507964.jpeg?auto=compress&cs=tinysrgb&w=600'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                </SectionCarrucel>

                <SectionCarrucel title="Ofertas" textlink="Ver ofertas" url="/Ofertas">
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7507964/pexels-photo-7507964.jpeg?auto=compress&cs=tinysrgb&w=600'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                </SectionCarrucel>

                <SectionCarrucel title="Servicios cercanos a ti" textlink="Ver más" url="/servicios">
                    <VideoInfoItem>
                        <InfoUser
                            imgProfile='https://github.com/mdo.png'
                            name='Jorge Prueba'
                            link='/usuario/jorge1235'
                            showVerify={false}
                            user=""
                        >
                            <span>Hace 1h</span>
                        </InfoUser>
                        <div className="clip-column__video">
                            <OnlyVideoItem
                                linkImg='https://images.pexels.com/photos/7561180/pexels-photo-7561180.jpeg?auto=compress&cs=tinysrgb&w=600'
                                linkVideoView='/watch?clip=7283157820948614406&v=clip'
                            >
                                <DescriptionVideo
                                    linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                    description='¿Funcionan? o son otro engaño mas? '
                                    listTag={['cocina', 'desinfectante']} />
                            </OnlyVideoItem>
                        </div>
                    </VideoInfoItem>
                    <VideoInfoItem>
                        <InfoUser
                            imgProfile='https://github.com/mdo.png'
                            name='Jorge Prueba'
                            link='/usuario/jorge1235'
                            showVerify={false}
                            user=""
                        >
                            <span>Hace 1h</span>
                        </InfoUser>
                        <div className="clip-column__video">
                            <OnlyVideoItem
                                linkImg='https://images.pexels.com/photos/7833380/pexels-photo-7833380.jpeg?auto=compress&cs=tinysrgb&w=600'
                                linkVideoView='/watch?clip=7283157820948614406&v=clip'
                            >
                                <DescriptionVideo
                                    linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                    description='¿Funcionan? o son otro engaño mas? '
                                    listTag={['cocina', 'desinfectante']} />
                            </OnlyVideoItem>
                        </div>
                    </VideoInfoItem>
                    <VideoInfoItem>
                        <InfoUser
                            imgProfile='https://github.com/mdo.png'
                            name='Jorge Prueba'
                            link='/usuario/jorge1235'
                            showVerify={false}
                            user=""
                        >
                            <span>Hace 1h</span>
                        </InfoUser>
                        <div className="clip-column__video">
                            <OnlyVideoItem
                                linkImg='https://images.pexels.com/photos/7833380/pexels-photo-7833380.jpeg?auto=compress&cs=tinysrgb&w=600'
                                linkVideoView='/watch?clip=7283157820948614406&v=clip'
                            >
                                <DescriptionVideo
                                    linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                    description='¿Funcionan? o son otro engaño mas? '
                                    listTag={['cocina', 'desinfectante']} />
                            </OnlyVideoItem>
                        </div>
                    </VideoInfoItem>
                    <VideoInfoItem>
                        <InfoUser
                            imgProfile='https://github.com/mdo.png'
                            name='Jorge Prueba'
                            link='/usuario/jorge1235'
                            showVerify={false}
                            user=""
                        >
                            <span>Hace 1h</span>
                        </InfoUser>
                        <div className="clip-column__video">
                            <OnlyVideoItem
                                linkImg='https://images.pexels.com/photos/7833380/pexels-photo-7833380.jpeg?auto=compress&cs=tinysrgb&w=600'
                                linkVideoView='/watch?clip=7283157820948614406&v=clip'
                            >
                                <DescriptionVideo
                                    linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                    description='¿Funcionan? o son otro engaño mas? '
                                    listTag={['cocina', 'desinfectante']} />
                            </OnlyVideoItem>
                        </div>
                    </VideoInfoItem>
                    <VideoInfoItem>
                        <InfoUser
                            imgProfile='https://github.com/mdo.png'
                            name='Jorge Prueba'
                            link='/usuario/jorge1235'
                            showVerify={false}
                            user=""
                        >
                            <span>Hace 1h</span>
                        </InfoUser>
                        <div className="clip-column__video">
                            <OnlyVideoItem
                                linkImg='https://images.pexels.com/photos/7833380/pexels-photo-7833380.jpeg?auto=compress&cs=tinysrgb&w=600'
                                linkVideoView='/watch?clip=7283157820948614406&v=clip'
                            >
                                <DescriptionVideo
                                    linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                    description='¿Funcionan? o son otro engaño mas? '
                                    listTag={['cocina', 'desinfectante']} />
                            </OnlyVideoItem>
                        </div>
                    </VideoInfoItem>
                    <VideoInfoItem>
                        <InfoUser
                            imgProfile='https://github.com/mdo.png'
                            name='Jorge Prueba'
                            link='/usuario/jorge1235'
                            showVerify={false}
                            user=""
                        >
                            <span>Hace 1h</span>
                        </InfoUser>
                        <div className="clip-column__video">
                            <OnlyVideoItem
                                linkImg='https://images.pexels.com/photos/7833380/pexels-photo-7833380.jpeg?auto=compress&cs=tinysrgb&w=600'
                                linkVideoView='/watch?clip=7283157820948614406&v=clip'
                            >
                                <DescriptionVideo
                                    linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                    description='¿Funcionan? o son otro engaño mas? '
                                    listTag={['cocina', 'desinfectante']} />
                            </OnlyVideoItem>
                        </div>
                    </VideoInfoItem>
                    <VideoInfoItem>
                        <InfoUser
                            imgProfile='https://github.com/mdo.png'
                            name='Jorge Prueba'
                            link='/usuario/jorge1235'
                            showVerify={false}
                            user=""
                        >
                            <span>Hace 1h</span>
                        </InfoUser>
                        <div className="clip-column__video">
                            <OnlyVideoItem
                                linkImg='https://images.pexels.com/photos/7833380/pexels-photo-7833380.jpeg?auto=compress&cs=tinysrgb&w=600'
                                linkVideoView='/watch?clip=7283157820948614406&v=clip'
                            >
                                <DescriptionVideo
                                    linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                    description='¿Funcionan? o son otro engaño mas? '
                                    listTag={['cocina', 'desinfectante']} />
                            </OnlyVideoItem>
                        </div>
                    </VideoInfoItem>
                    <VideoInfoItem>
                        <InfoUser
                            imgProfile='https://github.com/mdo.png'
                            name='Jorge Prueba'
                            link='/usuario/jorge1235'
                            showVerify={false}
                            user=""
                        >
                            <span>Hace 1h</span>
                        </InfoUser>
                        <div className="clip-column__video">
                            <OnlyVideoItem
                                linkImg='https://images.pexels.com/photos/7833380/pexels-photo-7833380.jpeg?auto=compress&cs=tinysrgb&w=600'
                                linkVideoView='/watch?clip=7283157820948614406&v=clip'
                            >
                                <DescriptionVideo
                                    linkVideoView='/watch?clip=7283157820948614406&v=clip'
                                    description='¿Funcionan? o son otro engaño mas? '
                                    listTag={['cocina', 'desinfectante']} />
                            </OnlyVideoItem>
                        </div>
                    </VideoInfoItem>

                </SectionCarrucel>

                <SectionCarrucel title="Historial" textlink="Ver más" url="/historial">
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7852677/pexels-photo-7852677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7507964/pexels-photo-7507964.jpeg?auto=compress&cs=tinysrgb&w=600'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7507964/pexels-photo-7507964.jpeg?auto=compress&cs=tinysrgb&w=600'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7507964/pexels-photo-7507964.jpeg?auto=compress&cs=tinysrgb&w=600'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7507964/pexels-photo-7507964.jpeg?auto=compress&cs=tinysrgb&w=600'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                    <VideoProductItem>
                        <OnlyVideoItem
                            linkImg='https://images.pexels.com/photos/7507964/pexels-photo-7507964.jpeg?auto=compress&cs=tinysrgb&w=600'
                            linkVideoView='/product/7283157820948614406'
                        >
                            <DetailProduct publicity={true} linkDetail="/product/34535-343fafsfasdfc" />
                        </OnlyVideoItem>
                    </VideoProductItem>
                </SectionCarrucel>
            </div>
        </main>
    );
};

export { ShopHome };
