import React from 'react';
import './Comments.scss';
import { Tolink } from '../ToLink/Tolink';
import { Reactions } from '../Reactions/Reactions';

interface Props {
    moreComments?: boolean
    children?: React.ReactNode
}
const Comments: React.FC<Props> = ({ children, moreComments = false }) => {
    return (
        <>
            <div role='group' className='social-comment-base__item'>
                <div className='social-comment__photo'>
                    <span className="social-comment__label-img">
                        <div>
                            <Tolink to='/usuario/perfiltercero_12' classlink='link-black'>
                                <img src="https://github.com/mdo.png" loading="lazy" alt="Foto de Jorge Balderas" id="ember137" className="EntityPhoto-circle-0 evi-image lazy-image ember-view" />
                            </Tolink>
                        </div>
                    </span>
                </div>
                <div className='social-comment__content'>
                    <div className='social-comment__content--info'>
                        <div className='social-comment-float__show--time-opcions'>
                            <div className='social-publish-comment-time'>
                                hace 11 días
                            </div>
                            <div className='social-publish-comment-opcions'>
                                <button aria-label="Abrir menú del cometario" id="ember136"
                                    data-bs-toggle="dropdown" aria-expanded="false"
                                    className="social-opcions-comment__action--button" >
                                    <i className="bi bi-three-dots-vertical"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="ember136">
                                    <samp className="dropdown-item">Denunciar</samp>
                                    {/* <samp className="dropdown-item">Action 3</samp> */}
                                </div>
                            </div>
                        </div>
                        <div className='social-comment__content--name'>
                            <Tolink to='/usuario/perfiltercero_12' classlink='link-black'>
                                Usuario 1
                            </Tolink>
                        </div>
                        <div className='social-comment__content--comment'>
                            <p>este es un comentario  este es un comentario este es un comentario este es un comentario</p>
                            <div className='social-reacctions-total'>
                                <button aria-label="5792 reacciones a la publicación de INSPIRAS" className='social--counts__count-value'>
                                    <img src="https://static.licdn.com/aero-v1/sc/h/8ekq8gho1ruaf8i7f86vd1ftt" alt="like" className='social-detail-social-counts__count-icon' />
                                    <img src="https://static.licdn.com/aero-v1/sc/h/cpho5fghnpme8epox8rdcds22" alt="like" className='social-detail-social-counts__count-icon' />
                                    <img src="	https://static.licdn.com/aero-v1/sc/h/3wqhxqtk2l554o70ur3kessf1" alt="like" className='social-detail-social-counts__count-icon' />
                                    <span className='social-details-social-counts__reactions-count'>5792</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='social-comment__content-reaccions'>
                        <div className='social-comment__content--bottons-reactiones'>
                            <span role='group' className="dropup">
                                <Reactions classButton='social-reaccions-comment__action--button social-bar-comment-button' />
                            </span>
                            <span role='group' className="social-details-bar__action--reaccions reactions-react-button">
                                <button type="button" className="social-reaccions-comment__action--button social-bar-comment-button  ">
                                    Responder
                                    {/* <span>1 respuesta</span> */}
                                </button>

                            </span>
                        </div>
                    </div>
                    <div className='social-comment-base__list-response'>
                        {children}
                    </div>
                </div>
            </div>
            {
                moreComments &&
                <div className='social-comment__content__list--comment'>
                    <div className='social-comment-content--totals-number'>
                        <button aria-label="Ver 253 comentarios más" className='social-details--counts__count-value'>
                            <span className='social-comment-content--totals-number'>Ver más comentarios (2563)</span>
                        </button>
                    </div>
                </div>
            }
        </>
    );
};

export { Comments };