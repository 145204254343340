import React from 'react';
import './SellerInformation.scss';
import { Button } from '../../Components/Button/Button';
import { InfoUser } from '../../Components/InfoUser/InfoUser';
import { Tolink } from '../../Components/ToLink/Tolink';
import { Stars } from '../../Components/Stars/Stars';

interface Props {
    showButtons?: boolean
    children?: React.ReactNode
}

const SellerInformation: React.FC<Props> = ({ showButtons = true, children }) => {
    return (
        <div className='SellerInformation information-seller'>
            <div className='information-seller--InfoSeller'>
                <p>Vendido por:</p>
                <Button classBtn='button_secundario ms-5'>Seguir</Button>
                <div className='information-seller-info'>
                    <InfoUser
                        showVerify={true}
                        name='Tienda A'
                        link='/store/tienda'
                        imgProfile='https://avatars.githubusercontent.com/u/197597?v=4'
                        user=""
                    >
                        <Stars score={4.5} ratings={2536} />
                        <div className='information-sales-cont-all'>
                            <span className='information-sales-all'>10K Ventas en los ulttimos 90 días</span>
                        </div>
                    </InfoUser>
                    <div className='information-seller-prestgio'>
                        Prestigio del vendedor
                    </div>
                </div>
            </div>

            <div className='information-info-sale'>
                <div className='information-price'>
                    <div className="information-price-money-amount-price">
                        <div>
                            <span className="andes-money-amount__discount">Oferta</span>
                            &nbsp; <samp className="andes-money-amount--previous font-bold">-34%</samp>
                        </div>
                        <div className="information-price-money-amount-price-current font-light mt-2">
                            <span className="information-price-money-amount--cents-superscript">
                                <span
                                    className="information-price-andes-money-amount__currency-symbol"
                                    aria-hidden="true"
                                >
                                    $
                                </span>
                                <span
                                    className="information-price-andes-money-amount__fraction"
                                    aria-hidden="true"
                                >
                                    3,653
                                </span>
                                <sup className=''> 00</sup>
                            </span>
                            &nbsp; &nbsp;
                            <span>
                                <samp className="information-price-andes-money-amount--previous">
                                    <span
                                        className="information-price-andes-money-amount__currency-symbol"
                                        aria-hidden="true"
                                    >
                                        $
                                    </span>
                                    <span
                                        className="information-price-andes-money-amount__fraction"
                                        aria-hidden="true"
                                    >
                                        5,589
                                    </span>
                                    <sup className=''> 00</sup>
                                </samp>
                            </span>
                        </div>

                        <div>
                            <span className="information-price-installments--card">
                                <span className="information-price-phrase-price andes-money-amount--card font-medium">
                                    <span>Hasta </span>
                                    <span>12</span>
                                    <span className="information-price-text"> MSI</span>
                                </span>
                                <span> de </span>
                                <span className='font-medium'>
                                    <span
                                        className="information-price-andes-money-amount__currency-symbol"
                                        aria-hidden="true"
                                    >
                                        $
                                    </span>
                                    <span
                                        className="information-price-andes-money-amount__fraction"
                                        aria-hidden="true"
                                    >
                                        523
                                    </span>
                                    <samp>.</samp>
                                    <span
                                        className="information-price-andes-money-amount__fraction"
                                        aria-hidden="true"
                                    >
                                        00
                                    </span>
                                </span>
                            </span>
                        </div>

                    </div>
                </div>
                <div className='information-cont-shipping'>
                    <Tolink to='/shipping-free'>
                        <span className='information-shipping'>
                            Envío GRATIS
                        </span>
                    </Tolink>

                    <div className='information-info-arrive'>
                        <p>
                            Recibe <strong>mañana 28 de Marzo</strong>, realizando tu pedido antes de 2 hrs 46 min
                        </p>
                    </div>

                    <div className='information-shipping-address'>
                        <samp>Enviar a </samp>
                        <Tolink to='/direcciones' classlink='toLink-clr-secundario'>
                            Municipio, ciudad, codigo postal
                        </Tolink>
                    </div>

                    <div className='information-cont-stock'>
                        <div className='information-stock'>
                            <strong>Stock disponible:</strong>
                            <samp> 485 disponibles</samp>
                        </div>
                        {showButtons &&
                            <div className='information-count-select'>
                                <label>
                                    <strong>Cantidad: </strong>
                                </label>
                                &nbsp;
                                <select className='information-list--select' aria-label="Seleccionar las unidades a comprar">
                                    <option value="1">1 Unidad</option>
                                    <option value="2">2 Unidades</option>
                                    <option value="3">3 Unidades</option>
                                    <option value="4">4 Unidades</option>
                                    <option value="5">5 Unidades</option>
                                    <option value="6">6 Unidades</option>
                                    <option value="7">7 Unidades</option>
                                    <option value="8">8 Unidades</option>
                                    <option value="9">9 Unidades</option>
                                </select>
                            </div>
                        }
                    </div>

                    <div className='information-cont-bottons'>
                        {showButtons &&
                            <>
                                <div>
                                    <Button>Comprar ahora</Button>
                                </div>
                                <div>
                                    <Button classBtn='button_secundario active'>Agregar al carrito</Button>
                                </div>
                            </>
                        }
                        {
                            !showButtons &&
                            children
                        }
                    </div>


                    <div className='information-cont-additional'>
                        <div className='information-additional-item'>
                            <div className='item-text-label'>Pago</div>
                            <div className='item-text-info'><Tolink to='/pagos' classlink='toLink-clr-secundario'>Transacción segura</Tolink></div>
                        </div>
                        <div className='information-additional-item'>
                            <div className='item-text-label'>Envio desde </div>
                            <div className='item-text-info'>Nuestra bodega</div>
                        </div>
                        <div className='information-additional-item'>
                            <div className='item-text-label'>Devoluciones </div>
                            <div className='item-text-info'>Se puede devolver hasta el 31 de enero de 2024</div>
                        </div>
                        <div className='information-additional-item'>
                            <div className='font-bold'><Tolink to='/pagos' classlink='toLink-clr-secundario'>Conoce los medios de pago</Tolink></div>
                        </div>
                    </div>

                    <div className='want-to-sell'>
                        <label className='want-to-sell-question'>¿Quieres vender un producto igual?</label>
                        <Tolink to='/vender'>
                            <Button classBtn='button_withe'>Si, quiero vender</Button>
                        </Tolink>
                    </div>
                </div>

            </div>
        </div>
    );
};

export { SellerInformation };