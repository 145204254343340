import React from 'react';
import './style.css'


interface Props {
    image:string,
    title:string
}
const SeccionNavInfoItem:React.FC<Props> = ({image, title}) => {
    return (
        <div className='contIcon'>
            <div className='contIcon_img'>
                {/* <div className="feature-icon bg-primary bg-gradient">
                    <Icon classIcon="cart-fill"></Icon>
                </div> */}
                <img src={image} alt="favorites" className="rounded-circle" />
            </div>
            <div className='contIcon_text'><span>{title}</span></div>
        </div>
    );
};

export {SeccionNavInfoItem};