import React from 'react';

const Afiliacion = () => {
    document.title = 'Afiliacion';
    return (
        <div>
            Estamos encantados de invitarte a unirte a nuestra red y comenzar a ganar comisiones promocionando nuestro producto.
            {/* Afiliacion Solo para usuarios verificados
            <ul>
                <li>El sistema tendrá una comición economica al aplicar a estos beneficios</li>
            </ul>
            <ul>
                <li>En la vista se mostraran los productos que las tiendas compartieron para afiliación</li>
                <li>La tienda podrá asignar si es afiliacion por ventas o por menciones</li>
                <li>El usuario mandará solicitud para afiliarce al producto</li>
                <li>Formas de ganar
                    <ul>
                        <li>Ganancias por venta
                            <ul>
                                <li>El producto tiene a la vista la ganancia por venta</li>
                                <li>El usuario solicita a la tienda el permiso de agregar producto al perfil del usuario</li>
                                <li>La tienda Evaluará al usuario y definirrá si acepta la solicitud o la rechaza</li>
                                <li>Al aceptar la solicitud, se le pedirá que envie una muestra del producto  </li>
                                <li>En caso de que el producto sea caró podría soliciar la tienda un porcentaje del costo del producto al usuario</li>
                                <li>Por cada venta que haga el usuario tendrá una ganancia</li>
                                <li>En este Caso de uso (El producto se le asignará a la tienda del usuario pero como detalle no se le mostrará el vendedor original)</li>
                            </ul>
                        </li>

                        <li>Menciones en clips
                            <ul>
                                <li>El producto tiene a la vista el costo por mencion que pagaría la tienda al usuario, podría ser un costo establecido por el sistema</li>
                                <li>La tienda tiene que crear las restricciones de las menciones</li>
                                <li>El usuario hace la solicitud</li>
                                <li>La tienda la acepta o la rechaza</li>
                                <li>Al aceptar la tienda realiza el pago</li>
                                <li>El pago le llegará al usuario hasta que realice las menciones establecidas</li>
                                <li>Los pagos serán desde el sistema</li>
                                <li>Los clips que publique el usuario se relacionarán al producto y se agregará a la lista de clips del usuario</li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul> */}
        </div>
    );
};

export { Afiliacion };