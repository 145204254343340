import React from 'react';
import './GroupClipItem.scss'


const GroupClipItem = () => {
    return (
        <div className='groupClips-content'>
            <div role='group' className="card groupClips">
                <div className="card-body">
                    <div className='groupClips-title'>
                        <span>Mi tienda</span>
                    </div>
                    <div className='groupClips-preview'></div>
                    <div className='groupClips-allClips'>
                        <span>24</span>&nbsp;&nbsp;
                        <span><i className="bi bi-collection-play"></i></span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { GroupClipItem };