import React from 'react';
import './PublicationItem.scss'


import { Button } from '../Button/Button';
import { Tolink } from '../ToLink/Tolink';
import { VideoPlayer } from '../VideoPlayer/VideoPlayer';
import { MenuOptionsPublication } from '../MenuOptionsPublication/MenuOptionsPublication';

const PublicationItem = () => {

    const [showAllDescription, setShowAllDescription] = React.useState(false)

    return (
        <section className="PublicationItem">

            <div role="status"></div>

            <h2 className="visually-hidden">
                Número de publicación en el feed 1
            </h2>

            <div className="publication-actor">
                <div className="publication-actor__container">
                    <Tolink to='/usuario/Platzi' classlink='publication-actor__image'>
                        <span className="js-publication-actor">
                            <div className="ivm-image-view-model    publication-actor__avatar">
                                <div className="ivm-view-attr__img-wrapper display-flex">
                                    <img
                                        width="48"
                                        src="https://avatars.githubusercontent.com/u/63648?v=4"
                                        loading="lazy"
                                        height="48"
                                        alt="Platzi"
                                        id="ember6085"
                                        className="ivm-view-attr__img--centered EntityPhoto-square-3  publication-actor__avatar-image evi-image lazy-image ember-view"
                                    />
                                </div>
                            </div>
                        </span>
                    </Tolink>
                    <div className="publication-actor__meta relative">
                        <Tolink to='/usuario/Platzi' classlink='publication-actor__meta-link'>
                            <span className="publication-actor__title">
                                <span className="publication-actor__name hoverable-link-text t-14 t-bold t-black">
                                    <span aria-hidden="true">
                                        <span dir="ltr">Jorge XX</span>
                                    </span>
                                    <span className="visually-hidden">Platzi</span>
                                </span>
                            </span>
                            <span className="publication-actor__description">
                                <span aria-hidden="true">205.781 seguidores</span>
                                <span className="visually-hidden">205.781 seguidores</span>
                            </span>
                        </Tolink>
                        {/* <a
                            className="publication-actor__meta-link"
                            aria-label="Ver: Platzi 205.781 seguidores"
                            target="_self"
                            href="/store/tienda"
                            data-test-app-aware-link=""
                        >
                            
                        </a> */}

                        <Tolink to='/store/tienda' classlink='publication-actor__sub-description-link'>
                            <span className="publication-actor__sub-description">
                                <div className="publication-text-view">
                                    <span>
                                        <span className='publication-text__view-time' aria-hidden="true">
                                            Hace 12 minutos •
                                            <span className="visually-hidden">12 minutos • </span>
                                        </span>
                                        &nbsp;
                                        <span className='publication-text__view-icon'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-globe-americas" viewBox="0 0 16 16">
                                                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484-.08.08-.162.158-.242.234-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z" />
                                            </svg>
                                        </span>
                                    </span>
                                </div>
                            </span>
                        </Tolink>
                    </div>
                </div>

                <div className="publication-shared-control-menu">
                    <Button classBtn='button_secundario'>
                        <span aria-hidden="true">Seguir</span>
                    </Button>
                    <MenuOptionsPublication>
                        <li><a className="dropdown-item" href="#">No me interesa</a></li>
                        <li><a className="dropdown-item" href="#">Denunciar</a></li>
                    </MenuOptionsPublication>

                </div>
            </div>


            <div className="publication-descripcion" tabIndex={-1}>
                <div className="publication-descripcion__text" tabIndex={-1}>
                    <div className={`publication-text ${showAllDescription ? 'publication-text_display_block' : 'publication-text_display_box'}`} dir="ltr">
                        <span className="break-words">
                            <span>
                                <span dir="ltr">
                                En este video, te llevamos a un recorrido por nuestra panadería artesanal. Conoce nuestro proceso de elaboración tradicional y descubre la amplia variedad de productos frescos que ofrecemos. 
                                    &nbsp;
                                    <a href="#" className='toLink-clr-secundario font-bold' data-attribute-index="0" data-entity-type="MINI_COMPANY">Link 1</a>&nbsp;y&nbsp;
                                    <a href="#" className='toLink-clr-secundario font-bold' data-attribute-index="2" data-entity-type="MINI_COMPANY">Link 2 </a>. <br />
                                    ¡Nos veremos de nuevo en 2024!
                                </span>
                            </span>
                        </span>
                    </div>

                    <button
                        className="colorless"
                        aria-label="ver más, muestra contenido visual que ya han detectado los lectores de pantalla"
                        type="button"
                        onClick={() => setShowAllDescription(!showAllDescription)}
                    >
                        <span className="feed-shared-inline-show-more-text__see-more-text">
                            {
                                !showAllDescription ? 'ver más' : 'ver menos'
                            }
                        </span>
                    </button>
                </div>
            </div>


            <div className="publication-video">
                <div className='publication-video__content'>
                    <div tabIndex={0} aria-label="Ver en pantalla completa" id="one-column-item-6" data-e2e="feed-video" className="he-100 publication-contentVideo">
                        {/* <canvas width="56.25" height="100" className="css-196h150-CanvasVideoCardPlaceholder e1bh0wg70"></canvas> */}
                        <div className="he-100 publication-video--video">
                            <div className="he-100 publication-video-content">
                                <div className="he-100">
                                    <div id="xgwrapper-0-7317802537145421061" className="tiktok-web-player no-controls VideoPlayer_visible">
                                        <VideoPlayer
                                            modelight={false}
                                            urlVideo='https://clisvi.com/Videos/Producto.mp4'
                                            linkToPage='/watch?clip=7283157820948614406'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="publication-video__actions">
                        <button
                            type="button"
                            aria-label="Dar me gusta a un video 121.6K me gusta"
                            aria-pressed="false"
                            className="colorless publication-video__actions--button"
                        >
                            <span data-e2e="like-icon" className="publication-video__actions--icon">
                                {/* recomiendo  */}
                                <i className='bi bi-hand-thumbs-up-fill'></i>
                                {/* Me interesa  */}
                                {/* <i className='bi bi-lightbulb-fill'></i> */}
                                {/* Me fasina  */}
                                {/* <i className='bi bi-heart-fill'></i> */}
                                {/* Gracioso  */}
                                {/* <i className="bi bi-emoji-laughing"></i> */}
                                {/* Me sorprende  */}
                                {/* <i className="bi bi-emoji-surprise"></i> */}
                            </span>
                            <strong data-e2e="like-count" className="publication-video__actions--text">121.6K</strong>
                        </button>
                        <Tolink to='/watch?clip=7283157820948614406&v=clip'>
                            <button
                                type="button"
                                aria-label="Leer o añadir comentarios 759 comentarios"
                                className="colorless publication-video__actions--button"
                            >
                                <span data-e2e="comment-icon" className="publication-video__actions--icon">
                                    <i className="bi bi-chat-right-dots-fill"></i>
                                </span>
                                <strong data-e2e="comment-count" className="publication-video__actions--text">759</strong>
                            </button>
                        </Tolink>

                        <button
                            type="button"
                            aria-label="Compartir un video 318 veces compartido"
                            aria-expanded="false"
                            className="colorless publication-video__actions--button"
                        >
                            <span data-e2e="share-icon" className="publication-video__actions--icon">
                                <i className="bi bi-share-fill"></i>
                            </span>
                            <strong data-e2e="share-count" className="publication-video__actions--text">318</strong>
                        </button>
                        <button
                            type="button"
                            aria-label="Añadir a Favoritos. Añadidos a Favoritos: 4244"
                            className="colorless publication-video__actions--button">
                            <span data-e2e="undefined-icon" className="publication-video__actions--icon">
                                <i className="bi bi-collection-play-fill"></i>
                            </span>
                            <strong data-e2e="undefined-count" className="publication-video__actions--text">4244</strong>
                        </button>
                    </div>

                </div>
            </div>

        </section>

    );
};

export { PublicationItem };