import React from 'react';

import "./Button.scss"
interface Props {
    children: React.ReactNode
    onClick?: () => void
    classBtn?: string
}
const Button: React.FC<Props> = ({ children, onClick, classBtn = 'button_primario active' }) => {
    return (
        <button onClick={onClick} type="button" className={`btn button ${classBtn}`}>
            {children}
        </button>
    );
};
export { Button };



