import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './Register.scss'

import logo from '../../Assets/Image/Logo/logo1.png';

interface Props {
    // Define any props here if needed
}

const Register: React.FC<Props> = () => {
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<string>('');
    const [phoneError, setPhoneError] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');
    const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');


    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

/**
 * The function handleSubmit validates email, phone, password, and confirm password fields and logs the
 * values if all validations pass.
 * @param e - The parameter `e` in the `handleSubmit` function is of type `React.FormEvent`, which is a
 * generic type for form events in React. This parameter represents the event that is being handled, in
 * this case, a form submission event. By calling `e.preventDefault()`, you are preventing the
 */
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!emailRegex.test(email)) {
            setEmailError('Por favor ingresa un correo electrónico válido');
        } else {
            setEmailError('');
        }
        if (!phoneRegex.test(phone)) {
            setPhoneError('Por favor ingresa un número de teléfono válido');
        } else {
            setPhoneError('');
        }
        if (!passwordRegex.test(password)) {
            setPasswordError('La contraseña debe tener al menos 8 caracteres, incluyendo al menos una mayúscula, una minúscula y un número');
        } else {
            setPasswordError('');
        }
        if (confirmPassword === '') {
            setConfirmPasswordError('Por favor verifica tu contraseña');
        } else if (password !== confirmPassword) {
            setConfirmPasswordError('Las contraseñas no coinciden');
        } else {
            setConfirmPasswordError('');
        }
        if (emailRegex.test(email) && phoneRegex.test(phone) && passwordRegex.test(password) && password === confirmPassword) {
            console.log(`Email: ${email}, Phone: ${phone}, Password: ${password}`);
        }
    };
    const handleGoogleSignUp = () => {
        // Agrega aquí la lógica para registrarse con Google
        console.log('Registrarse con Google');
    };

/**
 * The above functions toggle the visibility of password input fields in a TypeScript React component.
 */
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const toggleShowPasswordConfirm = () => {
        setShowPasswordConfirm(!showPasswordConfirm);
    };

    return (
        <div className="signup-container" >
            {/* style={{ backgroundImage: `url('https://via.placeholder.com/1500x1000')` }} */}
            <div className="signup-form">
                <div className="text-center mb-4">
                    <img src={logo} alt="Clisvi" className="logo mb-4" />
                    <h2 className="text- #E28000">Crear cuenta</h2>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Correo electrónico</label>
                        <input
                            type="email"
                            className={`form-control ${emailError && 'is-invalid'}`}
                            id="email"
                            placeholder="Correo electrónico"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                            required
                        />
                        {emailError && <div className="invalid-feedback">{emailError}</div>}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="phone" className="form-label">Teléfono</label>
                        <input
                            type="tel"
                            className={`form-control ${phoneError && 'is-invalid'}`}
                            id="phone"
                            placeholder="Teléfono"
                            maxLength={10}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            pattern="\d{10}"
                            required
                        />
                        {phoneError && <div className="invalid-feedback">{phoneError}</div>}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Contraseña</label>
                        <div className="input-group">
                            <input
                                type={showPassword ? "text" : "password"}
                                className={`form-control ${passwordError && 'is-invalid'}`}
                                id="password"
                                placeholder="Contraseña (mínimo 8 caracteres, incluyendo al menos una mayúscula, una minúscula y un número)"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}"
                                required
                            />
                            <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={toggleShowPassword}
                            >
                                {showPassword ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                            </button>
                        </div>
                        {passwordError && <div className="invalid-feedback">{passwordError}</div>}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="confirmPassword" className="form-label">Verificar contraseña</label>
                        <div className="input-group">
                            <input
                                type={showPasswordConfirm ? "text" : "password"}
                                className={`form-control ${confirmPasswordError && 'is-invalid'}`}
                                id="password"
                                placeholder="Contraseña (mínimo 8 caracteres, incluyendo al menos una mayúscula, una minúscula y un número)"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}"
                                required
                            />
                            <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={toggleShowPasswordConfirm}
                            >
                                {showPasswordConfirm ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                            </button>
                        </div>


                        {/* <input
                            type={showPassword ? "text" : "password"}
                            className={`form-control ${confirmPasswordError && 'is-invalid'}`}
                            id="confirmPassword"
                            placeholder="Verificar contraseña"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        /> */}
                        {confirmPasswordError && <div className="invalid-feedback">{confirmPasswordError}</div>}
                    </div>
                    <div className="mb-3">
                        <div className="d-grid">
                            <button type="submit" className="btn btn-primary">Registrarse</button>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="d-grid">
                            <button type="button" className="btn btn-google" onClick={handleGoogleSignUp}>
                                <i className="fab fa-google me-2"></i>Registrarse con Google
                            </button>
                        </div>
                    </div>
                    <div className="text-center">
                        <span>¿Ya tienes una cuenta? </span>
                        <Link to="/signin" className="link">Iniciar sesión</Link>
                    </div>
                </form>
            </div>
        </div>
    );
};

export { Register };