import React from 'react';
import './Home.scss'

import { Button } from '../../Components/Button/Button';
import { Tolink } from '../../Components/ToLink/Tolink';
import { PublicationItem } from '../../Components/PublicationItem/PublicationItem';
import { PublicationItemProduct } from '../../Components/PublicationItemProduct/PublicationItemProduct';
import { AccessLink } from '../../Components/AccessLink/AccessLink';
import { MyStore } from '../../Components/MyStore/MyStore';


const Home = () => {
  document.title = "Clisvi";
  return (
    <div className="Home">
      <aside className='Home-aside'>
        <section className='Home-aside-button'>
          <Tolink to='/vender'>
            <Button>Quiero vender</Button>
          </Tolink>
        </section>
        <section className='home-card-item'>
          <h2 className="home-card-title">
            Accesos
          </h2>
          <div className="home-card-main">

            <AccessLink link='/shop' name='Clisvi shop'>
              <i className="bi bi-shop"></i>
            </AccessLink>
            <AccessLink link='/watch?clip=7283157820948614406&v=clip' name='Clisvi watch'>
              <i className="bi bi-play-circle"></i>
            </AccessLink>
            <AccessLink link='/services' name='Clisvi Services'>
              <i className="bi bi-hammer"></i>
            </AccessLink> 
            <AccessLink link='/afiliacion' name='Afiliación'>
              <i className="bi bi-piggy-bank-fill"></i>
            </AccessLink>
            <AccessLink link='/historyclips' name='Actividad'>
              <i className="bi bi-clock-history"></i>
            </AccessLink>
            <AccessLink link='/lives' name='Lives'>
              <i className="bi bi-broadcast"></i>
            </AccessLink>

          </div>
        </section>

        <section className='home-card-item sticky'>
          <h2 className="home-card-title">
            Mis páginas (2)

            <Tolink to='/registro-pagina'>
              <span className='home-card-title_add'><i className="bi bi-plus-circle-fill"></i></span>
            </Tolink>
          </h2>
          <div className="home-card-main">
            <MyStore
              linkPage='/company/78751470/'
              name='Tienda'
              srcImage='https://avatars.githubusercontent.com/u/197597?v=4'
              notifications={0}
              linkNotifications='/company/78751470/notifications/'
            />

            <MyStore
              linkPage='/company/78751470/'
              name='GeorgeMystery'
              srcImage='https://avatars.githubusercontent.com/u/63648?v=4'
              notifications={2}
              linkNotifications='/company/78751470/notifications/'
            />

          </div>
        </section>

      </aside>


      <main className='home-main'>
        <div className='home-main__content'>
          <PublicationItemProduct />
          <PublicationItem />
          <PublicationItemProduct />
          <PublicationItem />
          <PublicationItemProduct />
          <PublicationItem />
          <PublicationItemProduct />
          <PublicationItem />
          <PublicationItemProduct />
          <PublicationItem />
        </div>
      </main>


      <aside className='Home-aside'>
        <section className='Home-aside-button'>
          <Tolink to='/afiliacion'>
            <Button>Publicar un Clip</Button>
          </Tolink>
        </section>
        <section className='home-card-item sticky'>
          <h2 className="home-card-title">
            Mis pedidos
          </h2>
          <div className="home-card-main">
            <AccessLink link='/carrito' name='Carrito (2)'>
              <i className="bi bi-cart"></i>
            </AccessLink>
            <AccessLink link='/pedidos' name='Mis pedidos'>
              <i className="bi bi-cart-check"></i>
            </AccessLink>
            <AccessLink link='/preguntas' name='Preguntas'>
              <i className="bi bi-chat-left-dots"></i>
            </AccessLink>
            <AccessLink link='/opiniones' name='Comparte experiencias (1)'>
              <i className="bi bi-film"></i>
            </AccessLink>

          </div>
        </section>

      </aside>
    </div>
  );
};

export { Home };
