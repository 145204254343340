import React, { ReactNode } from 'react';
import './SecctionNavInfo.css'


interface Props {
    className?: string,
    children: ReactNode
}

const SeccionNavInfo: React.FC<Props> = ({ children, className }) => {
    return (
        <nav className={className}>
            {children}
        </nav>
    );
};

export { SeccionNavInfo };