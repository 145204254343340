import React from 'react';
import './Logo.scss'
import { Tolink } from '../ToLink/Tolink';
import imgLogo from '../../Assets/Image/Logo/logo2.png'

const Logo = () => {
    return (
        <div className='nav-header-logo pt-3 pb-3'>
            <Tolink to='/' classlink='d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none'>
                <img className='nav-header-logo--img' src={imgLogo} alt="Logo Clisvi" srcSet=""/> 
                {/* <strong className='textLogo'>Clisvi</strong> */}
            </Tolink>
        </div>
    );
};

export { Logo };