import React from 'react';
import './Reactions.css'

interface Props {
    children?: React.ReactNode
    classButton?: string

}
const Reactions: React.FC<Props> = ({ children, classButton = "social-reaccions-comment__action--button social-bar-comment-button" }) => {
    return (
        <>
            <button type="button" className={`${classButton} `}
                data-bs-toggle="dropdown" aria-expanded="false">
                {children}
                Recomendar
                {/* <span>25 Reacciones</span> */}
            </button>
            <ul className="dropdown-menu social-reactions-accion-list--drop">
                <li className='social-reactions-accion--item social-emogi-action-recommend' aria-label="Lo recomiendo" data-bs-toggle="tooltip" data-bs-placement="top" title="Lo recomiendo">
                    <i className="bi bi-hand-thumbs-up-fill"></i>
                </li>
                <li className='social-reactions-accion--item social-emogi-action-interested' aria-label="Me interesa" data-bs-toggle="tooltip" data-bs-placement="top" title="Me interesa">
                    <i className="bi bi-lightbulb-fill"></i>
                </li>
                <li className='social-reactions-accion--item social-emogi-action-fascinate' aria-label="Me fascina" data-bs-toggle="tooltip" data-bs-placement="top" title="Me fascina">
                    <i className="bi bi-heart-fill"></i>
                    {/* <i className="bi bi-stars"></i> */}
                </li>
                <li className='social-reactions-accion--item social-emogi-action-funny' aria-label="Gracioso" data-bs-toggle="tooltip" data-bs-placement="top" title="Gracioso">
                    <i className="bi bi-emoji-laughing"></i>
                    {/* <i className="bi bi-emoji-laughing-fill"></i> */}
                </li>
                <li className='social-reactions-accion--item social-emogi-action-surprise' aria-label="Me sorprende" data-bs-toggle="tooltip" data-bs-placement="top" title="Me sorprende">
                    {/* <i className="bi bi-award-fill"></i> */}
                    <i className="bi bi-emoji-surprise"></i>
                </li>
            </ul>
        </>
    );
};

export { Reactions };