import React from 'react';
import './FollowerSuggestions.scss'
import { InfoUser } from '../../Components/InfoUser/InfoUser';

const FollowerSuggestions = () => {
    return (
        <div className="FollowerSuggestions card">
            <h4 className='Suggestions-title'>Sugerencias</h4>
            <div className='Suggestions-Follower-content'>
                <div className='Follower-item'>
                    <InfoUser imgProfile='https://github.com/mdo.png' name='Fredy bernal casio' link={`/usuario/fredy`} showVerify={true} showUser={true} user='fredy' />
                </div>
                <div className='Follower-item'>
                    <InfoUser imgProfile='https://github.com/mdo.png' name='Fredy' link={`/usuario/fredy`} showVerify={true} showUser={true} user='fredy' />
                </div>
                <div className='Follower-item'>
                    <InfoUser imgProfile='https://github.com/mdo.png' name='Fredy' link={`/usuario/fredy`} showVerify={true} showUser={true} user='fredy' />
                </div>
                <div className='Follower-item'>
                    <InfoUser imgProfile='https://github.com/mdo.png' name='Fredy' link={`/usuario/fredy`} showVerify={true} showUser={true} user='fredy' />
                </div>
                <div className='Follower-item'>
                    <InfoUser imgProfile='https://github.com/mdo.png' name='Fredy' link={`/usuario/fredy`} showVerify={true} showUser={true} user='fredy' />
                </div>
                <div className='Follower-item'>
                    <InfoUser imgProfile='https://github.com/mdo.png' name='Fredy' link={`/usuario/fredy`} showVerify={true} showUser={true} user='fredy' />
                </div>
                <div className='Follower-item'>
                    <InfoUser imgProfile='https://github.com/mdo.png' name='Fredy' link={`/usuario/fredy`} showVerify={true} showUser={true} user='fredy' />
                </div>
                <div className='Follower-item'>
                    <InfoUser imgProfile='https://github.com/mdo.png' name='Fredy' link={`/usuario/fredy`} showVerify={true} showUser={true} user='fredy' />
                </div>
            </div>

        </div>
    );
};

export { FollowerSuggestions };