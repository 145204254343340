import React from 'react';
import './PublicationItemProduct.scss'

import { Button } from '../Button/Button';
import { Stars } from '../Stars/Stars';
import { MenuOptionsPublication } from '../MenuOptionsPublication/MenuOptionsPublication';
import { Tolink } from '../ToLink/Tolink';

import { VideoPlayer } from '../VideoPlayer/VideoPlayer';

const PublicationItemProduct = () => {

    const [showAllDescription, setShowAllDescription] = React.useState(false)
    const [ActionControls, setActionControls] = React.useState({
        playing: false,
        volume: 0,
    })

    return (
        <section className="PublicationProductItem">

            <div role="status"></div>

            <h2 className="visually-hidden">
                Número de publicación en el feed 1
            </h2>

            <div className="publication-product-actor">
                <div className="publication-product-actor__container">
                    <Tolink to='/store/tienda' classlink='publication-product-actor__image'>
                        <span className="js-publication-product-actor">
                            <div className="ivm-image-view-model    publication-product-actor__avatar">
                                <div className="ivm-view-attr__img-wrapper display-flex">
                                    <img
                                        width="48"
                                        src="https://avatars.githubusercontent.com/u/197597?v=4"
                                        loading="lazy"
                                        height="48"
                                        alt="Platzi"
                                        id="ember6085"
                                        className="ivm-view-attr__img--centered EntityPhoto-square-3  publication-product-actor__avatar-image evi-image lazy-image ember-view"
                                    />
                                </div>
                            </div>
                        </span>
                    </Tolink>

                    <div className="publication-product-actor__meta relative">
                        <Tolink to='/store/tienda' classlink='publication-product-actor__meta-link'>
                            <span className="publication-product-actor__title">
                                <span className="publication-product-actor__name hoverable-link-text t-14 t-bold t-black">
                                    <span aria-hidden="true">
                                        <span dir="ltr">Xiaomi</span>
                                    </span>
                                    <span className="visually-hidden">Platzi</span>
                                </span>
                            </span>
                            <span className="publication-product-actor__description">
                                <span aria-hidden="true">205.781 seguidores</span>
                                <span className="visually-hidden">205.781 seguidores</span>
                            </span>
                            <Stars score={4.5} showTotal={false} ratings={0} />
                        </Tolink>
                    </div>
                </div>


                <div className="publication-product-shared-control-menu">
                    <Button classBtn='inactive'>
                        <span aria-hidden="true">Siguiendo</span>
                    </Button>
                    <MenuOptionsPublication>
                        <li><a className="dropdown-item" href="#">Agregar a favoritos</a></li>
                        <li><a className="dropdown-item" href="#">Agregar al carrito</a></li>
                    </MenuOptionsPublication>
                </div>
            </div>

            
            <div className="publication-product-descripcion" tabIndex={-1}>
                <div className="publication-product-descripcion__text" tabIndex={-1}>
                    <div className={`publication-product-text ${showAllDescription ? 'publication-product-text_display_block' : 'publication-product-text_display_box'}`} dir="ltr">

                        <div>
                            <Tolink to='/product/34535-343fafsfasdfc' classlink='toLink '>                            
                                <span className="break-words">
                                    <span>
                                        <span dir="ltr">
                                            Xiaomi Redmi 12 Dual SIM 128 GB azul océano 4 GB RAM
                                        </span>
                                    </span>
                                </span> 
                                &nbsp;
                                -
                                &nbsp;
                                <span className='toLink-clr-secundario '>
                                    Ver detalle
                                </span>
                            </Tolink>
                        </div>
                    </div>


                    {/* <button
                        className="colorless"
                        aria-label="ver más, muestra contenido visual que ya han detectado los lectores de pantalla"
                        type="button"
                        onClick={() => setShowAllDescription(!showAllDescription)}
                    >
                        <span className="feed-shared-inline-show-more-text__see-more-text">
                            {
                                !showAllDescription ? 'ver más' : 'ver menos'
                            }
                        </span>
                    </button> */}
                </div>

                <div className="publication-product-money-amount-price">
                    <div className='publication-product-info'>
                        <div className="publication-product__content-best-seller">
                            <span className="publication-product-best-seller">#10 En ventas</span>
                        </div>
                        <div className="publication-product-shipping-complete">
                            <span className="publication-product-shipping">Envío gratis</span>
                        </div>
                    </div>
                    <div className="publication-product-price-current">
                        <div>
                            <span>
                                <span className='publication-product'>Antes: </span>
                                <s className="publication-product--previous">
                                    <span className="publication-product-amount__currency-symbol" aria-hidden="true">$</span>
                                    <span className="publication-product-amount__fraction" aria-hidden="true">5,589</span>
                                </s>
                            </span>
                        </div>
                        <div>
                            <span className="publication-product--cents-superscript">
                                <span className="publication-product-amount__currency-symbol" aria-hidden="true">$</span>
                                <span className="publication-product-amount__fraction" aria-hidden="true">3,653</span>
                                &nbsp;
                                <sup>00</sup>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='mt-2'>
                <Tolink to='/product/34535-343fafsfasdfc' classlink='toLink-clr-secundario'>
                        <span>Click aqui para Ver detalle</span>
                </Tolink>
            </div> */}

            <div className="publication-product-video">
                <div className='publication-product-video__content'>
                    <div tabIndex={0} aria-label="Ver en pantalla completa" id="one-column-item-6" data-e2e="feed-video" className="he-100 publication-product-contentVideo">
                        {/* <canvas width="56.25" height="100" className="css-196h150-CanvasVideoCardPlaceholder e1bh0wg70"></canvas> */}
                        <div className="he-100 publication-product-video--video">
                            <div className="he-100 publication-product-video-content">
                                <div className="he-100">
                                    <div id="xgwrapper-0-7317802537145421061" className="tiktok-web-player no-controls VideoPlayer_visible">
                                        <VideoPlayer
                                            modelight={false}
                                            linkToPage='/product/34535-343fafsfasdfc' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="publication-product-video__actions">
                        <Tolink to='/product/34535-343fafsfasdfc'>
                            <button
                                type="button"
                                aria-label="Leer o añadir comentarios 759 comentarios"
                                className="colorless publication-product-video__actions--button"
                            >
                                <span data-e2e="comment-icon" className="publication-product-video__actions--icon">
                                    <i className="bi bi-eye"></i>
                                </span>
                                <strong data-e2e="comment-count" className="publication-product-video__actions--text">Detalle</strong>
                            </button>
                        </Tolink>  
                        <button
                            type="button"
                            aria-label="Dar me gusta a un video 121.6K me gusta"
                            aria-pressed="false"
                            className="colorless publication-product-video__actions--button"
                        >
                            <span data-e2e="like-icon" className="publication-product-video__actions--icon">
                                {/* recomiendo  */}
                                <i className='bi bi-hand-thumbs-up-fill'></i>
                                {/* Me interesa  */}
                                {/* <i className='bi bi-lightbulb-fill'></i> */}
                                {/* Me fasina  */}
                                {/* <i className='bi bi-heart-fill'></i> */}
                                {/* Gracioso  */}
                                {/* <i className="bi bi-emoji-laughing"></i> */}
                                {/* Me sorprende  */}
                                {/* <i className="bi bi-emoji-surprise"></i> */}
                            </span>
                            <strong data-e2e="like-count" className="publication-product-video__actions--text">121.6K</strong>
                        </button>
                        <Tolink to='/watch?clip=7283157820948614406&v=shop'>
                            <button
                                type="button"
                                aria-label="Leer o añadir comentarios 759 comentarios"
                                className="colorless publication-product-video__actions--button"
                            >
                                <span data-e2e="comment-icon" className="publication-product-video__actions--icon">
                                    <i className="bi bi-chat-right-dots-fill"></i>
                                </span>
                                <strong data-e2e="comment-count" className="publication-product-video__actions--text">759</strong>
                            </button>
                        </Tolink>
                        

                        <button
                            type="button"
                            aria-label="Compartir un video 318 veces compartido"
                            aria-expanded="false"
                            className="colorless publication-product-video__actions--button"
                        >
                            <span data-e2e="share-icon" className="publication-product-video__actions--icon">
                                <i className="bi bi-share-fill"></i>
                            </span>
                            <strong data-e2e="share-count" className="publication-product-video__actions--text">318</strong>
                        </button>
                        
                    </div>
                </div>
            </div>

        </section>
    );
};

export { PublicationItemProduct };