import React from 'react';
import './DescriptionVideo.scss'
import { Tolink } from '../ToLink/Tolink';

interface Props {
    linkVideoView: string
    description?: string
    listTag?: string[]
}

const DescriptionVideo: React.FC<Props> = ({
    linkVideoView,
    description = 'Aquí se introduce una descripción del video.',
    listTag = ['catalogo', 'ventas', 'mujeres', 'mujeremprendedora', 'cosmeticos', 'negociopropio', 'negocio', 'tipsdeseñora']
}) => {

    const [textLabel, setTextLabel] = React.useState('')

    React.useEffect(() => {
        let stringText = '';
        for (let tag of listTag) {
            stringText += `#${tag}`
        }
        setTextLabel(`${description} ${stringText}`)
    }, [description, listTag])

    return (
        <div className="item-descrip-content">
            <div
                aria-label={textLabel}
                data-e2e="user-post-item-desc" className="item-descrip__wrapper">
                <Tolink to={linkVideoView}>
                    <div className="item-descrip-clip">
                        <span className="item-descrip-clip--SpanText">
                            {description}
                        </span>
                        {
                            listTag.map((tag) => (
                                <strong key={tag} color="rgba(43, 93, 185, 1)" className="item-descrip-clip--StrongText">
                                    #{tag}
                                </strong>
                            ))
                        }
                        {/* <Tolink key={tag} to={`/tag/${tag}`}>
                        </Tolink> */}
                        {/* <a key={tag} href={`/tag/${tag}`} data-e2e="search-common-link" target="_self" rel="opener" className="item-descrip-clip--StyledCommonLink" aria-label="Watch more videos of the #fullerette category">
                            <strong color="rgba(43, 93, 185, 1)" className="item-descrip-clip--StrongText">
                                #{tag}
                            </strong>
                        </a> */}
                        <span className="item-descrip-clip--SpanText"></span>
                    </div>
                </Tolink>
                {/* <a title={textLabel}
                    href={linkVideoView} className="item-descrip--captionLine">
                    
                </a> */}
            </div>
            <span className="css-10ss1i1-SpanInfoExpandWrapper"></span>
        </div>
    );
};

export { DescriptionVideo };