import React, { FC, ReactNode } from 'react';
import { Tolink } from '../../ToLink/Tolink';
import "./Notification.scss"

interface Props {
    src: string,
    time: string,
    children: ReactNode
}
const ItemNotification: FC<Props> = ({ src, time, children }) => {
    return (
        <li>
            <Tolink to='' classlink='d-flex list-group-item list-group-item-action lh-tight mb-2'>
                <div className='d-flex align-items-center'>
                    <img src={src} alt="mdo" width="32" height="32" className="rounded-circle" />
                </div>
                <div className='ms-2 notification'>
                    <span className='notification-message'>
                        {children}
                    </span>
                    <div className='notification-time mt-2'>
                        <span>{time}</span>
                    </div>
                </div>
            </Tolink>
            <hr className="dropdown-divider" />
        </li>
    );
};

export { ItemNotification };