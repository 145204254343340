import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
    publicity: boolean;
    linkDetail: string
}
const DetailProduct: React.FC<Props> = ({ publicity = false, linkDetail }) => {
    return (
        <div className="column column--grid-card">
            <Link to={linkDetail} >
                <div className="row-card--title">
                    {publicity && (
                        <div className="publicity">
                            <span>Publicidad</span>
                        </div>
                    )}
                    <div className="column-best-seller">
                        <span className="best-seller">#10 En ventas</span>
                    </div>
                    <div className="column-title--description">
                        Xiaomi Redmi 12 Dual SIM 128 GB azul océano 4 GB RAM
                    </div>
                </div>

                <div className="money-amount-price">
                    <span className="andes-money-amount__discount">-34% OFF</span>
                    &nbsp; <samp className="andes-money-amount--previous font-bold">Oferta</samp>
                    <div className="money-amount-price-current">
                        <span className="money-amount--cents-superscript">
                            <span
                                className="andes-money-amount__currency-symbol"
                                aria-hidden="true"
                            >
                                $
                            </span>
                            <span
                                className="andes-money-amount__fraction"
                                aria-hidden="true"
                            >
                                3,653
                            </span>
                        </span>
                        &nbsp; &nbsp;
                        <span>
                            <s className="andes-money-amount--previous">
                                <span
                                    className="andes-money-amount__currency-symbol"
                                    aria-hidden="true"
                                >
                                    $
                                </span>
                                <span
                                    className="andes-money-amount__fraction"
                                    aria-hidden="true"
                                >
                                    5,589
                                </span>
                            </s>
                        </span>
                    </div>

                    <span className="price-installments--card">
                        <span className="phrase-price andes-money-amount--card">
                            <span>Hasta </span>
                            <span>12</span>
                            <span className="price-text"> MSI</span>
                        </span>
                    </span>
                </div>

                <div className="shipping-complete-wrapper">
                    <span className="shipping--wrapped">Envío gratis</span>
                </div>
            </Link>
        </div>
    );
};

export { DetailProduct };