import React from 'react';
import './ProfileUser.scss'
import { Tolink } from '../../Components/ToLink/Tolink';
import { Button } from '../../Components/Button/Button';


interface Props {
    imgProfile: string
    name: string
    user: string
    desciption: string
    noFollowing: string
    noFollowers: string
}

const ProfileUser: React.FC<Props> = ({ imgProfile, name, user, desciption, noFollowing, noFollowers }) => {
    return (
        <section className='profile-secction-header'>
            <div className='profile-header'>
                <div className='profile-header--content-banner'>
                    <div className='profile-header--banner'>
                        <img src="https://i.pinimg.com/originals/3b/20/bf/3b20bf22bd77577f9881becd558eccf5.jpg" className="d-block w-100" alt="..." />
                    </div>
                </div>
            </div>
            <div className='profile-main w-100'>
                <div className='profile__head w-100'>
                    <div className='profile__head-wrapper'>
                        <div className='profile__head-wrapper-picture'>
                            <div className='profile__head--picture'>
                                <img src={imgProfile} alt="" sizes="" />
                            </div>
                        </div>
                        <div className='profile__head-wrapper-info'>
                            <div>
                                <div className='profile__head-info--name'>
                                    <span className='profile-main--info-name'>{name}</span>
                                    <span className='profile-main--info-verify' data-bs-toggle="tooltip" data-bs-placement="top" title="Cuenta verificada">
                                        <i className="bi bi-patch-check-fill"></i>
                                    </span>
                                </div>

                                <div className='profile__head-info--user'>
                                    <span className='profile-main--info-user'>@{user}</span>
                                </div>
                            </div>
                        </div>
                        <div className='profile-wrapper--followers'>
                            <p className='profile--number-followers'>
                                <Tolink to='' classlink='toLink'>
                                    <span className='info-followers--number'>{noFollowing} </span>
                                    <samp className='info-followers--text'>Siguiendo</samp>
                                </Tolink>
                                &nbsp;&nbsp;
                                <Tolink to='' classlink='toLink'>
                                    <samp className='info-followers--number'>{noFollowers} </samp>
                                    <samp className='info-followers--text'>Seguidores</samp>
                                </Tolink>
                            </p>
                        </div>
                        <div className='profile__head-brand-description'>
                            <div className='profile__head--description'>
                                <p>
                                    {desciption}
                                    {/* Este es el lema de la empresa Este es el lema de la empresa Este es el lema de la empresa Este es el lema de la empresas */}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='profile__head-wrapper-right'>
                        <div className='profile__head-wrapper-followers'>
                            <div className='brand-buttons--follower'>
                                <Button classBtn='button_secundario '>
                                    Seguir
                                </Button>
                            </div>
                            <div className='brand-buttons--message'>
                                <Button classBtn='button_secundario active'>
                                    Mensaje
                                </Button>
                            </div>
                            <div className='brand-buttons--config'>
                                <div className="dropdown">
                                    {/* <Button classBtn='button_grey'>
                                            <i className="bi bi-three-dots-vertical"></i>
                                        </Button> */}
                                    <button className="btn btn-secondary button_opcions" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical"></i>
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item" href="#">Denunciar</a></li>
                                        <li><a className="dropdown-item" href="#">Bloquear</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export { ProfileUser };