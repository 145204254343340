import React from "react";

const Search = () => {
  return (
    <form className="col-12 col-lg-auto mb-lg-0 nav-header-search">
      <input
        type="search"
        className="form-control"
        placeholder="Busca $productos, @usuarios o @paginas..."
        aria-label="Search"
      />
    </form>
  );
};

export { Search };
